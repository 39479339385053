@import '../../assets/scss/base.scss';
.myOrderMain {
  :global {
    .ant-tabs-nav {

      .ant-tabs-tab-active {
        color: #333333;
      }
    }

    .ant-tabs-ink-bar {
      background-color: $baseRed;
    }
  }
}

.formitemRow {
  :global {
    .ant-form-item {
      display: flex;
    }

    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
}

.filterBtns {
  text-align: right;

  .btn {
    padding: 0px;
    width: 88px;
    margin-left: 15px;

    &:first-child {
      margin-left: 0px;
    }
  }
}

.noticeRow {
  padding: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  &:nth-child(odd) {
    background-color: #e6f7ff;
  }

  .badgeDot {
    position: relative;
    display: inline-block;
    top: -1px;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    border-radius: 50%;

    &.dotRed {
      background-color: $baseRed;
    }
  }

  .noticeText {
    margin-left: 8px;
    font-size: 14px;
    color: #333333;
    line-height: normal;
  }
}

.paginationLand {
  margin: 15px 0px;
  text-align: center;
}

.Tab {
  :global {
    padding-left: 10px;
    padding-right: 10px;

    .ant-tabs-nav {
      .ant-tabs-tab-active {
        color: #F03A58;
      }

      .ant-tabs-tab:hover {
        color: #F03A58;
      }
    }

    .ant-tabs-ink-bar {
      background-color: #F03A58;
    }
  }
}