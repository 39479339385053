@import '../../../assets/scss/base.scss';
.orderGoodsList {
  margin: 0px 0px 32px 0px;
}

.productListTitle {
  margin: 0px 20px 4px 20px;
  font-size: 14px;
  color: #333333;
}

.productList {
  margin: 0px 20px;
}

.listRow {
  position: relative;
  border-bottom: 1px solid rgba(153,153,153, 0.2);

  &:last-child {
    border-bottom: none;
  }
}
