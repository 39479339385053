@import '../../assets/scss/base.scss';
.menuBar {
  background-color: #ffffff;
}

.menuWrap {
  margin-bottom: 0px;

  li {
    display: inline-block;
    vertical-align: top;
  }

  .categorys {
    position: relative;

    .cateWrap {
      display: none;
      position: absolute;
      top: 48px;
      left: 0px;
      width: 200px;
      height: 450px;
      background-color: #ffffff;
      z-index: 20;
    }

    &.dropdown {
      .cateWrap {
        display: block;
      }
    }
  }

  .menuItem {
    display: flex;
    padding: 0px 26px;
    height: 48px;
    align-items: center;
    font-size: 16px;
    color: #4c4c4c;

    &.curNav {
      color: $baseRed;
    }

    &.cateTitle {
      width: 200px;
      justify-content: center;
      color: #ffffff;
      background-color: #0169C6;
    }

    .hotImg {
      margin-left: 5px;
      width: 20px;
    }
  }
}