@import '../../../assets/scss/base.scss';
.orderTableHeader {
  display: flex;
  margin: 0px 10px 15px 10px;

  li {
    height: 45px;
    line-height: 45px;
    text-align: center;
    background-color: #ECECEC;
    font-size: 12px;
    color: #666666;
    font-weight: 400;
  }

  .orderCol {
    flex: 1;
  }

  .amountCol {
    width: 160px;
  }

  .consigneeCol, .statusCol, .actionCol {
    width: 120px;
  }
}

