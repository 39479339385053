@import '../../../assets/scss/base.scss';
.btns {
  text-align: center;
}

.btn {
  width: 90px;
  border-radius: 2px;
}

.confirm {
  background-color: $baseRed;
  border-color: $baseRed;

  &:hover, &:focus {
    background-color: $baseRed;
    border-color: $baseRed;
  }
}