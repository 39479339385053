@import '../../../assets/scss/base.scss';

.label:after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}

.totalLand {
  margin: 24px 24px 10px 10px;
  font-size: 14px;
  color: #333333;
  text-align: right;

  em {
    font-size: 16px;
    color: $baseRed;
    font-weight: 600;
  }
}

.actionLand {
  margin: 0px 24px 0px 10px;
  text-align: right;
  font-size: 14px;
  color: #333333;
}

.explain {
  margin-right: 10px;
}

.submitBtn {
  background-color: $baseRed;

  &:focus, &:hover {
    background-color: $baseRed;
    border-color: $baseRed;
    color: #FFFFFF;
  }
}