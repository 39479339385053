@import '../../../assets/scss/base.scss';
.verifyModal {
  position: absolute;
  top: -50px;
  left: 0px;
  width: 360px;
  padding: 15px 12px;
  background-color: #ffffff;
  box-shadow: 0 0 2px 2px #eee;
  border: 1px solid #eee;
  z-index: 30;

  .verifyContent {
    position: relative;

    .modalClose {
      position: absolute;
      top: -15px;
      right: -12px;
      display: block;
      width: 35px;
      height: 35px;
      text-align: center;
      line-height: 35px;
    }
  }

  .modalHeader {
    display: flex;
    margin-bottom: 5px;
  }

  .title {
    flex: 1;
    font-size: 14px;
    color: #333333;
  }
}