@import '../../../assets/scss/base.scss';
.userHeader {
  position: relative;
  padding-left: 15px;
  margin-bottom: 0px;
  height: 45px;
  line-height: 45px;
  font-size: 14px;
  color: #000000;
  font-weight: 700;
  background-color: #FFFFFF;

  &::before {
    content: '';
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 3px;
    background-color: $baseRequired;
  }
}

.bottomSpace {
  display: block;
  width: 100%;
  height: 10px;
  background-color: #F8F8F8;
}