@import '../../../assets/scss/base.scss';

.modalContainer {
  position: relative;
  margin: 0px auto;
  width: 360px;
}

.formitemRow {
  :global {
    .ant-input-affix-wrapper {
      .ant-input:not(:first-child) {
        padding-left: 100px;
      }

      .ant-input:not(:last-child) {
        padding-right: 130px;
      }
    }
  }
}

.formitemLabel {
  font-size: 14px;
  color: #333333;

  //*样式带*号
  .required::before {
    display: inline-block;
    margin-right: 4px;
    color: $baseRequired;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.btns {
  text-align: center;
}

.btn {
  width: 90px;
  border-radius: 2px;
}

.confirmBtn {
  background-color: $baseRed;
  border-color: $baseRed;

  &:hover, &:focus {
    background-color: $baseRed;
    border-color: $baseRed;
  }
}