@import '../../assets/scss/base.scss';
.Tab {
  :global {
    padding-left: 10px;
    padding-right: 10px;

    .ant-tabs-nav {
      .ant-tabs-tab-active, .ant-tabs-tab:hover {
        color: $baseRed;
      }
    }

    .ant-tabs-ink-bar {
      background-color: $baseRed;
    }
  }
}