@import '../../../assets/scss/base.scss';
.invoiceAccount {
  margin-bottom: 15px;
  width: 100%;
  border: 1px solid #e8e8e8;
  font-size: 14px;
  color: #333333;

  td {
    border-bottom: 1px solid #e8e8e8;
  }
}

.label {
  padding: 10px 16px;
  width: 150px;
  border-right: 1px solid #e8e8e8;
}

.inner {
  padding: 10px 16px;
  flex: 1;
}