@import '../../../assets/scss/base.scss';
.orderSettlement {
  margin: 0px 20px;
  font-size: 14px;
  color: #333333;
  text-align: right;
}

.tradeLand {
  padding: 15px 10px 15px 0px;
  background-color: #f4f4f4;
  border-top: 1px solid #e6e6e6;

  p {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0px;
    }

    em {
      font-size: 16px;
      color: $baseRed;
      font-weight: 600;
    }
  }
}

.label::after {
  content: ':';
  position: relative;
  top: -.5px;
  margin: 0 8px 0 2px;
}

.payBtns {
  margin-top: 10px;

  .payBtn {
    width: 100px;
    height: 40px;
    background-color: $baseRed;
    border-radius: 2px;
  }
}