@import '../../assets/scss/base.scss';
.loginContent {
  margin: 0px auto;
  width: 360px;
}

.loginTabs {
  display: flex;

  a {
    display: block;
    flex: 1;
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-size: 18px;
    color: #666666;

    &:hover {
      color: $baseRed;
    }

    &.active {
      color: $baseRed;
      font-weight: 500;
    }
  }
}

.forgetLand {
  margin-bottom: 20px;
  text-align: right;

  a {
    font-size: 12px;
    color: #666666;

    &:hover {
      color: $baseRed;
      text-decoration: underline;
    }
  }
}

.loginBtn {
  display: block;
  width: 100%;
  height: 44px;
  font-size: 18px;
  background-color: $baseRed;
}

.coagentLand {
  margin-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 50px;
  height: auto;
  background-color: #fcfcfc;
  border-top: 1px solid #e8e8e8;
  text-align: right;

  .registerLink {
    font-size: 14px;
    color: $baseRed;

    &:hover {
      text-decoration: underline;
    }
  }
}

.landWrap {
  position: relative;
}