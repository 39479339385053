@import '../../../assets/scss/base.scss';

.footerWrap {
  margin-top: 10px;
  display: block;
  background-color: #f8f8f8;
}

.footerInfo {
  background-color: #ffffff;
}

.copyLand {
  min-width: 1200px;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 30px;
}

.copyLink {
  margin-bottom: 24px;
  color: #333333;
  font-size: 14px;
  text-align: center;
}

.copyRight {
  text-align: center;

  a {
    font-size: 12px;
    color: #999999;
    margin-right: 24px;

    &:last-child {
      margin-right: 0px;
    }

    &:hover {
      color: $baseRed;
    }
  }
}


.infoBusi {
  width: 1200px;
  padding: 25px 0px;
  margin: 0px auto;

  .busiItem {
    float: left;
    width: 240px;
    text-align: center;

    .pic {
      display: inline-block;
      vertical-align: middle;
      width: 60px;
    }

    .txt {
      margin-left: 10px;
      display: inline-block;
      vertical-align: middle;
      font-size: 18px;
      color: #333333;
      line-height: normal;
    }
  }
}
