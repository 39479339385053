@import '../../assets/scss/base.scss';
.productListHeader {
  display: flex;
  margin: 0px 20px 10px 20px;
  background-color: #f1f1f1;

  li {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #434343;
  }
}

.selectionCol {
  margin-left: 15px;
}

.productCol {
  margin-left: 95px;
  width: 265px;
}

.selection {
  .productCol {
    margin-left: 55px;
  }
}

.priceCol {
  flex: 1;
  display: flex;
  margin-left: 25px;

  .priceCell {
    flex: 1;
  }

  .quantityCell {
    width: 120px;
    text-align: center;
  }
}

.totalCol {
  margin-left: 16px;
  width: 120px;
}

.operationCol {
  margin-left: 16px;
  width: 100px;
  text-align: center;
}