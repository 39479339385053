@import '../../assets/scss/base.scss';
.userWrap {
  display: flex;
  margin: 0px auto;
  width: 1200px;
  min-height: 674px;
}

.userLayoutContent {
  flex: 1;
  margin-top: 10px;
  margin-left: 10px;
  background-color: #FFFFFF;
}