@import '../../../assets/scss/base.scss';
.invoiceProduct {
  margin-bottom: 15px;

  :global {
    .ant-table table {
      border-radius: 0px;
    }

    .ant-table-thead>tr>th,
    .ant-table-tbody>tr>td {
      padding: 10px 16px;
    }

    .ant-table-thead > tr:first-child > th:last-child {
      border-top-right-radius: 0px;
    }

    .ant-table-thead > tr:first-child > th:first-child {
      border-top-left-radius: 0px;
    }
  }
}

.label {
  position: relative;
  color: rgba(0, 0, 0, 0.85);
}

.label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}