@import '../../assets/scss/base.scss';
.shelvesPage {
  padding-bottom: 32px;
}

.pagingZone {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
}
