@import '../../assets/scss/base.scss';

.brandIntro {
  .floorTpl1, .floorTpl3, .floorTpl4 {
    padding: 0px 16px;
  }
}

.floorRow {
  
  margin-bottom: 24px;
}

.flex {
  display: flex;
}

.pullLeft {
  margin-right: 24px;
  width: 300px;

  .pic {
    display: block;
    width: 100%;
    border: 0px;
  }
}

.pullRight {
  margin-left: 24px;
  width: 300px;

  .pic {
    display: block;
    width: 100%;
    border: 0px;
  }
}

.mediaBody {
  flex: 1;
  display: flex;
  align-items: center;

  .desc {
    font-size: 14px;
    color: #333333;
  }
}

.floorTitle {
  position: relative;
  display: flex;
  height: 56px;
  align-items: center;

  .line {
    flex: 1;
    height: 1px;
    background: linear-gradient(90deg,rgba(192, 174, 255, 1) 0%,rgba(38, 88, 255, 1) 100%);

    &.rotate {
      transform: rotate(180deg);
    }
  }

  .text {
    flex: 1;
    padding: 0px 10px;
    height: 56px;
    line-height: 56px;
    font-size: 22px;
    color: #333333;
    text-align: center;
    font-weight: 400;
  }
}

.floorTpl2 {
  .pic {
    display: block;
    max-width: 1200px;
    margin: 0px auto;
    border: 0px;
  }
}

.floorTpl5 {
  background-color: rgb(248, 248, 248);

  .floorCover {
    width: 240px;
    transition: all 0.2s linear;

    &:hover {
      box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
      transform: translate3d(0, -2px, 0);
    }

    .pic {
      display: block;
      width: 100%;
      border: 0px;
    }
  }

  .span16 {
    flex: 1;
  }

  .brickItem {
    position: relative;
    float: left;
    width: 220px;
    height: 300px;
    padding: 16px 0px;
    margin-left: 20px;
    margin-bottom: 16px;
    background: #fff;
    transition: all 0.2s linear;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
      transform: translate3d(0, -2px, 0);
    }

    .figure {
      margin: 0 auto 16px;
      width: 160px;
      height: 160px;
      overflow: hidden;

      .figurePic {
        display: block;
        width: 160px;
        height: 160px;
        border: 0px;
      }
    }

    .name {
      margin: 0px 10px 10px 10px;
      font-size: 14px;
      color: #333333;
      text-align: center;
      height: 32px;
      line-height: 16px;
      overflow: hidden;
    }

    .desc {
      margin: 0px 10px;
      text-align: center;
      font-size: 12px;
      color: #999999;
      height: 28px;
      line-height: 14px;
      overflow: hidden;
    }
  }
}