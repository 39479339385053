@import '../../../../assets/scss/base.scss';
.actionLand {
  display: flex;
  margin-bottom: 10px;
}

.actionPart {
  margin-left: 20px;
  flex: 1;
  height: 32px;
  line-height: 32px;

  &:first-child {
    margin-left: 0px;
  }

  label {
    margin-right: 6px;
    font-size: 14px;
    color: #333333;
  }

  a {
    font-size: 12px;
  }
}