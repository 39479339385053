@import '../../../assets/scss/base.scss';
.totalList {
  display: flex;
  margin: 0px 20px 32px 20px;
}

.totalItem {
  flex: 1;
}

.itemTitle {
  height: 40px;
  line-height: 40px;
  background-color: #F1F1F1;
  font-size: 14px;
  color:#333333;
  text-align: center;
}

.itemContent {
  height: 40px;
  line-height: 40px;
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  text-align: center;

  em {
    color: $baseRed;
  }
}