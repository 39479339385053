@import '../../../assets/scss/base.scss';
.first {
  height: 40px;
  border: 1px solid #E8E8E8;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.first_label {
  padding-left: 10px;
  font-weight: bold;
  font-size: 14px;
}

.first_dastlist {
  width: 450px;
  height: 100%;
}


.first_dastlist_tabs {
  height: 35px;

  :global {

    .ant-tabs-nav .ant-tabs-tab {
      position: relative;
      display: inline-block;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      height: 100%;
      margin: 0 0px 0 0;
      padding: 12px 10px;
      text-decoration: none;
      cursor: pointer;
      -webkit-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .ant-tabs-nav .ant-tabs-tab-active {
      color: #1890ff;
      font-weight: 500;
    }

  }
}