@import '../../assets/scss/base.scss';
.emptyWrapper {
  margin: 0px auto;
  width: 700px;
  margin-top: 40px;
}

.emptyImg {
  width: 115px;
  height: 123px;
  margin: 0px auto 48px auto;

  &.emptyProd {
    background: url('../../assets/images/icon/empty-prod.png') no-repeat;
    background-size: 100% 100%;
  }

  &.emptyCart {
    background: url('../../assets/images/icon/cart4.png') no-repeat;
    background-size: 100% 100%;
  }
}



.emptyText {
  margin-bottom: 72px;

  p {
    text-align: center;
    font-size: 24px;
    color: #595959;
    font-family: "微软雅黑";
  }

  .searchword {
    padding: 0px 8px;
    color: $baseRed;
    font-weight: 700;
  }
}

.emptyBtns {
  margin-bottom: 64px;
  text-align: center;

  .btn {
    display: block;
    margin: 0px auto;
    width: 200px;
    height: 50px;
    line-height: 50px;
    font-size: 22px;

    &.redBtn {
      background-color: $baseRed;
      color: #fff;
    }
  }
}

.recomLand {
  p {
    margin-bottom: 16px;
    font-size: 24px;
    color: #999999;
    text-align: center;
  }

  .recomSearch {
    text-align: center;
    font-size: 16px;
    color: $baseRed;
  }

  .recomItem {
    display: inline-block;
    vertical-align: top;
    padding-left: 8px;
    padding-right: 8px;
    line-height: normal;

    &.divierLine {
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 3px;
        bottom: 3px;
        right: 0px;
        width: 2px;
        background-color: $baseRed;
      }
    }
  }
}