@import '../../../assets/scss/base.scss';
.productIntro {
  padding: 20px;
  background-color: #ffffff;
}

.previewWrap {
  float: left;
  width: 300px;
}

.infoWrap {
  float: left;
  width: calc(100% - 330px);
  margin-left: 30px;
}

.skuName {
  margin-bottom: 10px;
  width: 100%;
  font-size: 16px;
  color: #333333;
  line-height: normal;
}

.skuBrand {
  padding: 1px 2px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: 0px;
  border: 1px solid #0169C6;
  border-radius: 2px;
  font-size: 12px;
  color: #0169C6;
  word-break: break-all;
  line-height: normal;
}

.choose {
  margin-bottom: 5px;
  font-size: 14px;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .chooseLabel {
    position: relative;

    &::after {
      content: ':';
      position: relative;
      top: -.5px;
      margin: 0 8px 0 2px;
    }
  }
}

.priceLand {
  margin-top: 10px;
  margin-bottom: 10px;
}

.priceWrap {
  padding: 10px 15px;
  width: 680px;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
}

.priceRow {
  margin-top: 5px;

  &:first-child {
    margin-top: 0px;
  }

  .priceSection {
    margin-bottom: 10px;
    font-size: 12px;
    color: #333333;

    .price {
      margin-left: 8px;
      font-size: 18px;
      color: #F03A58;
    }
  }

  .rowContent {
    float: left;
    width: 450px;
  }

  .inlineCol {
    display: inline-block;

    &.priceInline {
      width: 140px;
    }
  }

  .alignCenter {
    display: flex;
    align-items: center;
    height: 26px;
  }

  .rowAction {
    float: left;

    .minOrder {
      margin-left: 8px;
      font-size: 12px;
    }
  }
}

.tagKey {
  display: inline-block;
  padding: 0px 2px;
  font-size: 12px;
  color: #0169C6;
  border: 1px solid #0169C6;
  border-radius: 2px;
  line-height: normal;
  box-sizing: content-box;

  &.futuresTag {
    color: #F03A58;
    border-color: #F03A58;
  }
}

.tagValue {
  margin-left: 8px;
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
  color: #999999;
  box-sizing: border-box;
}

.buyBtn {
  padding: 0px;
  width: 90px;
  background-color: #F03A58;
  border-color: #F03A58;
  border-radius: 2px;

  &:focus {
    background-color: #F03A58;
    border-color: #F03A58;
  }
}
