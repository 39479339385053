@import '../../../assets/scss/base.scss';
.inline {
  display: inline-block;
  vertical-align: top;
}

.categoryToggle {
  width: 200px;
}

.categoryNav {
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;

  .menuTitle {
    display: flex;
    align-items: center;
    height: 44px;
    color: #333333;
    font-size: 14px;

    span {
      display: -webkit-box;
      overflow: hidden;
      text-overflow: -o-ellipsis-lastline;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &.menuActive {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.13s ease-in-out;
  }
}

.categoryCon {
  position: relative;
  width: 100%;

  .categoryPop {
    display: block;
    padding: 10px 20px;
    position: absolute;
    top: 0px;
    left: 200px;
    width: 1000px;
    height: 460px;
    background-color: #ffffff;
    z-index: 20;
    overflow-y: auto;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  }

  .menulistScroll {
    height: 100%;

    .floorList {
      position: relative;
      padding-top: 10px;
      padding-bottom: 12px;

      &.border1px:after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 100px;
        right: 0px;
        height: 1px;
        background-color: #dddddd;
      }
    }

    .floorTitle {
      position: absolute;
      top: 12px;
      left: 0px;
      padding-right: 10px;
      height: 100%;
      line-height: 16px;
      width: 100px;
      font-size: 12px;
      color: rgba(51, 51, 51, 0.75);
      font-weight: 600;
      z-index: 24;
      background-color: #ffffff;
    }

    .floor2Container {
      float: left;
      width: 850px;
      margin-left: 90px;
    }

    .categoryPart {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0px;
      }

      .dropContainer {
        display: inline-block;
        vertical-align: top;
        padding: 0px 12px;
        line-height: normal;

        &.divierLine {
          position: relative;

          &:before {
            content: '';
            position: absolute;
            top: 5px;
            bottom: 2px;
            left: 0px;
            width: 1px;
            background-color: #dddddd;
          }
        }

        .dprodName {
          font-size: 12px;
          color: #666666;

          &:hover {
            color: $baseRed;
          }
        }
      }

    }
  }
}