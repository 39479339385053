@import '../../../assets/scss/base.scss';
.flowItem {
  padding: 12px 20px 12px 20px;
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid #e8e8e8;
 
  .flowMeta {
    flex: 1;

    .metaTitle {
      margin-bottom: 5px;
      font-size: 14px;
      color: #333333;
    }

    .metaDesc {
      color: #666666;
    }
  }

  .flowCon {
    font-size: 16px;
    color: $baseRed;
    font-weight: 700;
    text-align: right;
    min-width: 100px;
  }
}

.paginaRow {
  margin: 16px 0px;
  text-align: right;
}