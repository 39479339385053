@import '../../assets/scss/base.scss';
.productListFooter {
  display: flex;

  li {
    height: 50px;
    user-select: none;
  }
}

.checkArea {
  margin-left: 15px;
  line-height: 50px;

  .checkText {
    margin-left: 5px;
    margin-right: 10px;
    font-size: 12px;
    color: #333333;
    line-height: normal;

    &:hover {
      color: $baseRed;
    }
  }
}

.totalArea {
  flex: 1;
  margin-left: 15px;
  line-height: 50px;
  text-align: right;
  font-size: 14px;
  color: #666666;

  em {
    font-size: 16px;
    color: $baseRed;
    font-weight: 500;
  }

  .fTotal {
    margin-left: 20px;
  }
}

.btnsArea {
  margin-left: 15px;
}

.btn {
  display: inline-block;
  vertical-align: middle;
  width: 130px;
  line-height: 50px;
  text-align: center;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 400;

  &:hover {
    color: #FFFFFF;
  }
}

.downBtn {
  background-color: #00A5FF;
}

.submitBtn {
  background-color: $baseRed;
}