@import '../../../assets/scss/base.scss';
.sourceLand {
  margin: 0px 20px;
}

.batchSearchWrap {
  display: flex;
  margin: 0px 20px 20px 20px;
}

.batchInputs {
  position: relative;
  flex: 1;
}

.formitemRow {
  :global {
    .ant-form-item {
      display: flex;
    }

    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
}

.batchBtns {
  margin-left: 24px;
  width: 200px;
}

.batchBtn {
  display: flex;
  padding: 0px;
  margin-top: 10px;
  width: 200px;
  height: 45px;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: $baseLinkBlue;
  border-color: $baseLinkBlue;
  border-radius: 2px;

  &:first-child {
    margin-top: 0px;
  }

  &.batchSearch {
    color: #FFFFFF;
    background-color: $baseRed;
    border-color: $baseRed;
  }
}