@import '../../assets/scss/base.scss';

.loginHeader {
  height: 100px;
  background-color: #ffffff;

  .headerContainer {
    display: flex;
    margin: 0px auto;
    width: 1200px;
    height: 100px;
    align-items: center;

    .imgBox {
      display: flex;
      width: 200px;
      height: 60px;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }

    .logoPic {
      display: block;
      height: 60px;
    }
  }

  .loginTitle {
    margin-left: 10px;
    height: 34px;
    line-height: 34px;
    font-size: 24px;
    color: #333;
  }
}