@import '../../../assets/scss/base.scss';
.productList {
  margin: 0px 20px;
}

.listRow {
  position: relative;
  border-bottom: 1px solid rgba(153,153,153, 0.2);

  &:last-child {
    border-bottom: none;
  }
}