@import '../../../assets/scss/base.scss';
.orderReceiving {
  margin: 0px 20px 32px 20px;
}

.header {
  display: flex;
  margin-bottom: 10px;

  h4 {
    margin-bottom: 0px;
    flex: 1;
    font-size: 16px;
    color: #333333;
  }
}

.extra {
  margin-left: 15px;
  margin-right: 15px;
  font-size: 14px;
  color: #1890FF;

  &:hover {
    color: #1890FF;
    text-decoration: underline;
  }
}

.container {
  position: relative
}

.openedLand {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.6), #ffffff);
  z-index: 10;

  a {
    font-size: 16px;
    color: #1890FF;
  }

  a:hover {
    color: #1890ff;
    text-decoration: underline;
  }
}

.addressList {
  height: 74px;
  overflow: hidden;

  &.opened {
    padding-bottom: 34px;
    transition: height, 0.3s;
    height: auto;
  }
}

.addressItem {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 6px;
  height: 34px;
  align-items: center;

  &:last-child {
    margin-bottom: 0px;
  }

  &:hover {
    background-color: #ECF9FF;

    .actionBtn {
      visibility: visible;
    }
  }
}

.detail {
  flex: 1;
  margin-left: 12px;
  margin-right: 12px;
  font-size: 14px;
  color: #999999;
}

.action {
  width: 120px;
  text-align: right;
}

.actionBtn {
  margin-left: 15px;
  font-size: 14px;
  color: #1890FF;
  visibility: hidden;

  &:hover {
    text-decoration: underline;
  }
}

.selected {
  .content {
    color: #000000;
  }
}