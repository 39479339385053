@import '../../../../assets/scss/base.scss';

.brickBox {
  margin: 0px auto;
  width: 1200px;
}

.boxHeader {
  display: flex;
  align-items: center;
  height: 50px;

  .floorIcon {
    display: block;
    margin-right: 10px;
    width: 22px;
    height: 22px;
  }

  .title {
    margin: 0px;
    font-size: 22px;
    color: #333333;
    font-weight: bold;
    font-family: 'Microsoft YaHei';
  }
}

.boxBody {
  background-color: #ffffff;

  .span4 {
    float: left;
    width: 240px;
    height: 470px;
    overflow: hidden;
  }

  .span16 {
    float: left;
    width: 960px;
  }
}

.coverBox {
  height: 470px;
  overflow: hidden;

  .coverPic {
    display: block;
    width: 100%;
  }
}

.brickItem {
  float: left;
  padding-bottom: 8px;
  width: 240px;
  border-left: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;

  &:nth-child(4n + 1) {
    border-left: none;
  }

  .pic {
    display: block;
    margin: 20px auto;
    width: 130px;
    height: 130px;
    transition: All 0.6s;
    

    &:hover {
      cursor: pointer;
      transform: translate(0, -8px) scale(1.05);
    }
  }

  .desc {
    display: flex;
    margin: 0px 20px;

    .descItem {
      flex: 1;
      
      .more {
        font-size: 14px;
        color: #f03a58;
        line-height: normal;
      }

      &.qLine {
        text-align: right;

        span {
          display: inline-block;
          vertical-align: middle;
          line-height: normal;
          color: #F03A58;
          font-size: 13px;
        }

        .qIcon {
          display: inline-block;
          vertical-align: middle;
          margin-right: 3px;
          width: 13px;
          height: 15px;
        }
      }
    }
  }

  .nameLand {
    margin: 5px 20px 0px 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #333333;
    height: 30px;
    line-height: 15px;
    text-align: left;
  }
}
