@import '../../assets/scss/base.scss';
.otherHeader {
  width: 100%;
  position: fixed;
  height: 70px;
  margin: 0 auto;
  background-color: #FFFFFF;
  top: 0px;
  
 
  .link {
    float: right;
    margin-left: 75%;
    line-height: 70px;
    font-size: 18px;
    color: #666666;

    &:hover{
      color: $baseRed;
    }
  }

  .headerContainer {
    display: flex;
    margin: 0px auto;
    width: 1200px;
    height: 70px;


    .imgBox {
      display: flex;
      width: 200px;
      height: 70px;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }

    .logoPic {
      display: block;
      height: 60px;
      line-height: 60px;
    }

    .flex1 {
      flex: 1;
    }

    .headerRight {
      margin-left: 15px;
      display: flex;
      align-items: center;

      a {
        font-size: 18px;
        color: #666666;

        &:hover {
          color: $baseRed;
        }
      }
    }
  }
}