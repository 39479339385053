@import '../../assets/scss/base.scss';

.stepsBox {
  margin: 0px 20px;
  padding: 30px 0px;
  display: flex;
}

.stepsItem {
  position: relative;
  flex-shrink: 1;
  flex-basis: 50%;
}

.stepHead {
  position: relative;
  width: 100%;
}

.line {
  position: absolute;
  top: 11px;
  left: 0px;
  right: 0px;
  height: 6px;
  border-color: inherit;
  background-color: #F1F1F1;
}

.leftRadius {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.rightRadius {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.stepIcon {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  font-size: 14px;
  border-radius: 50%;
  background-color: $baseRed;
  box-sizing: border-box;
  transition: 0.15s ease-out;

  .iconner {
    color: #ffffff;
  }
}

.stepMain {
  text-align: center;
  white-space: normal;
}

.stepText {
  margin-top: 10px;
  font-size: 14px;
  color: #333333;
}

.activeStep {
  .line {
    background-color: $baseRed;
    border-radius: 3px;
  }

  .stepText {
    color: $baseRed;
  }
}