@import 'assets/scss/base.scss';
ol, ul, p {
	margin-bottom: 0px;
	list-style: none;
}

.clearfix {
  zoom: 1;
}

.clearfix:after {
  content: '';
  display: block;
  clear: both;
  visibility: hidden;
  height: 0;
}

.loadingWrap {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(191, 191, 191, 0.25);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  z-index: 1100;
}

.inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pic {
  display: block;
  width: 50px;
  height: 50px;
}

.loadingText {
  margin-top: 8px;
}