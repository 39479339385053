@import '../../../assets/scss/base.scss';
.tableHead {
  display: flex;
  background-color:#F1F1F1;

  .headCell {
    margin-left: 15px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #333333;

    &:first-child {
      margin-left: 0px;
    }

    &.tGoods {
      padding-left: 95px;
      width: 340px;
    }

    &.tPrice {
      flex: 1;
      text-align: center;
    }

    &.tQuantity {
      width: 120px;
    }

    &.tSum {
      width: 120px;
    }

    &.tAction {
      width: 100px;
      text-align: center;
    }
  }
}

.priceRowWrap {
  display: flex;

  .editCell {
    flex: 1;
  }

  .noEditCell {
    width: 120px;
  }
}

.listRow {
  position: relative;
  padding: 12px 0px;
  border-bottom: 1px solid rgba(153,153,153, 0.2);

  &:last-child {
    border: none;
  }
}

.listContainer {
  display: flex;

  &.blur {
    opacity: 0.5;
  }
}

.disabledMask {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 29;
  background-color: rgba(51, 51, 51, 0.05);
}

.disabledBtn {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: #F03A58;
  color: #FFFFFF;
  font-size: 14px;
  text-align: center;
  line-height: 60px;

  &:hover {
    color: #FFFFFF;
  }
}

.productItem {
  position: relative;
  padding: 12px 0px;
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);

  .listRow {
    display: flex;
    padding: 0px;
    border-bottom: none;
  }
}

.cell {
  margin-left: 15px;
  font-size: 14px;

  &:first-child {
    margin-left: 0px;
  }

  &.goodsCell {
    width: 340px;
  }
  
  &.priceCell {
    flex: 1;
    margin-left: 24px;
  }

  &.sumCell {
    width: 120px;
  }
  
  &.actionCell {
    width: 100px;
    text-align: center;

    .btn {
      font-size: 14px;
      color: #1890FF;
    }
  }
}

.imgBox {
  float: left;
  display: flex;
  align-items: center;
  width: 80px;
  height: 80px;
  border: 1px solid #eee;
  box-sizing: border-box;
  background-color: #ffffff;
  overflow: hidden;
  
  .pic {
    display: block;
    width: 100%;
    border: none;
  }
}

.goodsMsg {
  margin-left: 95px;
  width: 245px;
  font-size: 14px;
  color: #000000;
  line-height: normal;
  overflow: hidden;

  .brand {
    display: inline-block;
    margin-bottom: 5px;
    padding: 1px 2px;
    border-radius: 2px;
    border: solid 1px #0169C6;
    font-size: 12px;
    color: #0169C6;
    line-height: normal;
  }

  .prodName {
    display: block;
    width: 245px;
    font-size: 14px;
    color: #333333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: normal;

    &:hover {
      color: #F03A58;
    }
  }

  .prodProps {
    font-size: 12px;
    color: #999999;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .propsLabel {
      position: relative;

      &::after {
        content: ':';
        position: relative;
        top: -.5px;
        margin: 0 6px 0 2px;
      }
    }
  }
}

.priceRow {
  margin-top: 5px;

  &:first-child {
    margin-top: 0px;
  }

  .priceSection {
    font-size: 14px;
    color: #333333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.priceCol {
  display: inline-block;
  vertical-align: top;
  width: 410px;
}

.quantityCol {
  display: inline-block;
  vertical-align: top;
  margin-left: 15px;
  width: 120px;
  height: 24px;
}

.inlineCol {
  display: inline-block;
  vertical-align: middle;
  width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.inlineFutures {
    width: 130px;
  }

  .alignCenter {
    display: flex;
    align-items: center;
    height: 24px;
  }
}

.tagKey {
  display: inline-block;
  vertical-align: top;
  padding: 0px 2px;
  font-size: 12px;
  color: #0169C6;
  border: 1px solid #0169C6;
  border-radius: 2px;
  line-height: normal;
  box-sizing: content-box;

  &.futuresTag {
    color: #F03A58;
    border-color: #F03A58;
  }
}

.tagValue {
  margin-left: 8px;
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
  color: #999999;
  box-sizing: border-box;
}

.footerBtns {
  margin-top: 15px;
  text-align: right;

  .btn {
    padding: 0px;
    width: 120px;
    height: 40px;
    background-color: #F03A58;
    border-radius: 2px;
  }
}

.emptyWrap {
  padding-top: 25px;
}