@import '../../assets/scss/base.scss';
.tableWrap {
  padding-top: 10px;
  margin: 0px 10px;
}

.btn {
  color: $baseLinkBlue;

  &:hover {
    color: $baseRed;
  }
}
