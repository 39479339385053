@import '../../../assets/scss/base.scss';

.footerWrap {
  display: block;
  margin-top: 10px;

  .black {
    background-color: #00549D;
  }
}

.footerInfo {
  background-color: #ffffff;
  color: #333333;
}

.footerFloor2 {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: rgba(248, 248, 248, 1);
}

.copyLink {
  margin-bottom: 24px;
  color: #666666;
  text-align: center;
}

.copyRight {
  text-align: center;

  a {
    font-size: 12px;
    color: #666666;
    margin-right: 24px;

    &:last-child {
      margin-right: 0px;
    }
  }
}

.infoBusi {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  width: 1200px;
  height: 130px;

  .infoItem {
    margin-right: 90px;

    &:last-child {
      margin-right: 0px;
    }
  }

  .iconPic {
    display: block;
    margin: 0px auto 10px auto;
    width: 51px;
    height: 51px;
  }

  .txt {
    font-size: 18px;
    font-weight: bold;
  }
}
.foot_div{
  background: royalblue;
  width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
}


