@import '../../assets/scss/base.scss';
.container {
  margin: 0px 10px;
  padding-bottom: 24px;
}

.tabLand {
  :global {
    .ant-tabs-nav .ant-tabs-tab-active {
      color: $baseRed;
    }

    .ant-tabs-ink-bar {
      background-color: $baseRed;
    }
  }
}

.dangerBtn {
  background-color: $baseRed;
}