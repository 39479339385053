@import '../../assets/scss/base.scss';

.register {
  border-top: 1px solid #BABABA;
  background-color: #ffffff;
  min-height: calc(100vh - 100px - 235px);
}

.registerContent {
  padding-bottom: 30px;
  margin: 0px auto;
  width: 1200px;
}

.title {
  margin: 50px auto 20px auto;
  width: 400px;
  font-size: 16px;
  color: #999999;
}

.stepWrap {
  .registerSteps {
    margin: 0px auto 30px auto;
    width: 400px;

    :global {
      .ant-steps-item-process {
        .ant-steps-item-icon {
          background-color: #52c41a;
          border-color: #52c41a;
        }
      }
    }
  }
}

.container {
  margin: 0px auto;
  width: 400px;
}

.registerFooter {
  margin: 0px auto;
  width: 400px;

  .registerOther {
    padding-bottom: 30px;

    .otherBtn {
      font-size: 14px;
      color: #666666;

      &:hover {
        color: $baseRed;
      }
    }
  }
}

.agreeScroll {
  max-height: 350px;
  overflow-y: scroll;
  user-select: none;

  .agreeCon {
    padding-right: 10px;

    p {
      margin-bottom: 10px;
    }
  }
}

.registerResult {
  padding: 24px 0px;

  .resultIcon {
    margin-bottom: 24px;
    text-align: center;
  }

  .resultTitle {
    font-size: 24px;
    color: #333333;
    text-align: center;
  }

  .resultSubtitle {
    margin-bottom: 24px;
    font-size: 14px;
    color: #666666;
    text-align: center;
  }

  .resultBtns {
    text-align: center;

    .btn {
      margin-right: 20px;
      width: 100px;
      height: 40px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}
