@import '../../../assets/scss/base.scss';
.formitemRow {
  :global {
    .ant-form-item {
      display: flex;
    }

    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
}

.tableWrap {
  margin-top: 24px;

  :global {
    .ant-table-thead>tr>th,
    .ant-table-tbody>tr>td {
      padding: 8px 12px;
    }
  }
}

.innerWrap {
  position: relative
}

.tagWrap {
  position: absolute;
  top: 4px;
  right: 0px;
  transform: rotate(35deg);
  z-index: 100;
}

.tag {
  display: inline-block;
  padding: 0px 8px;
  height: auto;
  font-size: 22px;
  font-weight: 400;
  color: #1892FF;
  border: 4px solid #1890FF;
  border-radius: 22px;
}

.pictureWrap {
  display: inline-block;
  margin-left: 10px;
  vertical-align: top;
  zoom: 1;

  &:first-child {
    margin-left: 10px;
  }
}

.span {
  display: inline-block;
  margin-left: 16px;

  &:first-child {
    margin-left: 0px;
  }
}

.sum {
  font-size: 24px;
  font-weight: 400;
  color: #333333;
}

.label {
  position: relative;
  color: #333333;

  &::after {
    content: ':';
    position: relative;
    top: -.5px;
    margin: 0 8px 0 2px;
  }
}