@import '../../assets/scss/base.scss';

.btn {
  width: 120px;
  height: 38px;
  border-radius: 2px;
}

.redColor {
  background-color: $baseRed;
  border-color: $baseRed;
  
  &:hover, &:focus {
    background-color: $baseRed;
    border-color: $baseRed;
  }
}