@import '../../../assets/scss/base.scss';
.disableMask {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(51, 51, 51, 0.05);
  z-index: 29;
  user-select: none;
}

.disabledBtn {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: $baseRed;
  color: #FFFFFF;
  font-size: 14px;
  text-align: center;
  line-height: 60px;

  &:hover {
    color: #FFFFFF;
  }
}