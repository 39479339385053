@import '../../assets/scss/base.scss';
.productListItem {
  display: flex;
  padding: 15px 0px;
}

.selectionCol {
  margin-left: 15px;
  margin-right: 8px;
}

.productCol {
  width: 360px;
  overflow: hidden;
}

.priceCol {
  flex: 1;
  margin-left: 16px;
}

.totalCol {
  margin-left: 16px;
  width: 120px;
}

.operationCol {
  margin-left: 16px;
  width: 100px;
  text-align: center;
}

.imgBox {
  float: left;
  display: flex;
  align-items: center;
  width: 80px;
  height: 80px;
  border: 1px solid #eeeeee;
  cursor: pointer;
  overflow: hidden;

  .pic {
    display: block;
    width: 80px;
  }
}

.productMedia {
  margin-left: 95px
}

.brand {
  display: inline-block;
  margin-bottom: 5px;
  padding: 1px 2px;
  border-radius: 2px;
  border: solid 1px #0169C6;
  font-size: 12px;
  color: #0169C6;
  line-height: normal;
}

.name {
  margin-bottom: 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: normal;

  .text {
    font-size: 14px;
    color: #333333;

    &:hover {
      color: $baseRed;
    }
  }
}

.prodProps {
  position: relative;
  padding-right: 62px;
  font-size: 12px;
  line-height: 24px;
  color: #999999;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .propsLabel {
    position: relative;

    &::after {
      content: ':';
      position: relative;
      top: -.5px;
      margin: 0 6px 0 2px;
    }
  }

  .propsTag {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 0px 4px;
    margin-right: 0px;
    line-height: 18px;
  }
}

.deleteBtn {
  font-size: 14px;
  color: $baseLinkBlue;
}

.buyBtn {
  padding: 0px;
  width: 90px;
  border-radius: 2px;
  background-color: $baseRed;
  border-color: $baseRed;

  &:hover, &:focus {
    background-color: $baseRed;
    border-color: $baseRed;
  }
}

.priceRow {
  display: flex;
  margin-top: 5px;

  &:first-child {
    margin-top: 0px;
  }

  .priceCell {
    flex: 1;
  }

  .quantityCell {
    width: 120px;
    text-align: center;
  }
}

.inlineCol {
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .alignCenter {
    display: flex;
    align-items: center;
    height: 24px;
  }

  &.priceInline {
    width: 130px;
  }
}

.tagKey {
  display: inline-block;
  vertical-align: top;
  padding: 0px 2px;
  font-size: 12px;
  color: #0169C6;
  border: 1px solid #0169C6;
  border-radius: 2px;
  line-height: normal;
  box-sizing: content-box;

  &.futuresTag {
    color: $baseRed;
    border-color: $baseRed;
  }
}

.tagValue {
  margin-left: 8px;
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
  color: #999999;
  box-sizing: border-box;
}

.sectionPrice {
  margin-bottom: 2px;
  font-size: 14px;
  color: #333333;
}