@import '../../../assets/scss/base.scss';

.orderSuccess {
  padding: 80px 40px 80px 72px;
  background-color: #fafafa;
  
  .successImg {
    display: inline-block;
    vertical-align: top;
    margin-right: 64px;
    width: 105px;
    height: 105px;
    background: url('~@/assets/images/icon/success2.png') no-repeat;
    background-size: 100% 100%;
  }

  .successBody {
    display: inline-block;
    width: calc(100% - 169px);

    .payTip {
      margin-right: 24px;
      width: 400px;

      h4 {
        margin-bottom: 32px;
        font-size: 24px;
        color: #333;
      }

      p {
        font-size: 18px;
        color: #333333;

        em {
          padding: 0px 4px;
          color: $baseRed;
        }
      }
    }

    .balanceInfo {
      flex: 1;
      font-size: 18px;
      color: #000000;

      .price {
        display: inline-block;
        width: 180px;
        font-size: 26px;
        font-weight: 700;
        color: $baseRed;
        text-align: left;
      }

      .balance {
        line-height: normal;
        text-align: right;
      }

      .payce {
        line-height: normal;
        text-align: right;
      }
    }
  }
}
