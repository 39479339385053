@import '../../../../assets/scss/base.scss';

.workList {
  max-height: 360px;
  overflow-y: scroll;
}

.workRow {
  display: flex;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0px;
  }

  .workCell:first-child {
    margin-left: 0px;
  }
}

.workCell {
  margin-left: 8px;
  flex: 1;
  background-color: #F8F8F8;
  overflow: hidden;
}

.cellValue {
  margin: 0px 12px;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}