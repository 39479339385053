@import '../../assets/scss/base.scss';

.registerHeader {
  display: flex;
  align-items: center;
  height: 100px;
  background-color: #ffffff;

  .headerContainer {
    margin: 0px auto;
    width: 1200px;
    height: 60px;

    .imgBox {
      float: left;
      display: flex;
      width: 200px;
      height: 60px;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }

    .logoPic {
      display: block;
      height: 60px;
    }
  }

  .loginTitle {
    float: left;
    height: 34px;
    line-height: 34px;
    font-size: 24px;
    color: #333;
    margin-top: 15px;
    margin-left: 10px;
  }

  .loginAccount {
    float: right;
    margin-top: 30px;
    font-size: 16px;
    color: #999999;

    a {
      color: $baseRed;
      
      &:hover {
        color: #c81623;
        text-decoration: underline;
      }
    }
  }
}