@import '../../assets/scss/base.scss';
.overView {
  display: flex;
  margin-bottom: 10px;
  text-align: center;

  .creditOver {
    display: flex;
    flex: 1;
    padding: 20px 0px;

    .creditLim {
      flex: 1;
    }

    .creditTime {
      flex: 1;

      .timeTit {
        font-size: 14px;
        font-weight: 600;
        color: #333333;
      }

      .zdHint {
        color: $baseRed;
      }

      .repayInfo {
        margin-bottom: 10px;
      }

      .repayDate, .period {
        color: $baseRed;
        font-size: 14px;
        line-height: normal;
      }

      .place {
        width: 100px;
        height: 158px;
        color: rgba(255, 255, 255, 1);
      }

      .frozen {
        padding-right: 30px;
        text-align: left;
      }
    }

    .hint {
      font-size: 12px;
      color: $baseRed;
    }
  }
}

.balanceOver {
  padding: 20px 0px;
  width: 300px;
  border-right: 1px solid #e8e8e8;

}

.overCellTitle {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #333333;
}

.iconImg {
  display: block;
  margin: 0px auto;
  height: 48px;
}

.num {
  margin: 15px 0px;
  color: $baseRed;
  font-size: 18px;
  font-weight: 700;
}

.overBtn {
  display: inline-block;
  padding: 3px 15px;
  border-radius: 6px;
  background-color: $baseRed;
  color: #ffffff;
  font-size: 12px;

  &:hover {
    color: #FFFFFF;
  }
}

.walletTabRow {
  margin: 0px 10px;

  :global {
    .ant-tabs-nav {  
      .ant-tabs-tab-active, .ant-tabs-tab:hover {
        color: $baseRed;
      }
    }
            
    .ant-tabs-ink-bar {
      background-color: $baseRed;
    }
  }
}
