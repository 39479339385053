@import '../../assets/scss/base.scss';
.inline {
  display: inline-block;
  vertical-align: top;
}


.myOrderMain {
  background: white;
  padding: 0px 10px;
  :global {
    .ant-tabs-nav {

      .ant-tabs-tab-active {
        color: $baseRed;
      }
    }

    .ant-tabs-ink-bar {
      background-color: $baseRed;
    }
  }
}

.formitemWrap {
  position: relative;
  height: 40px;

  .uncompanyLand {
    padding-bottom: 15px;
    position: absolute;
    top: 40px;
    left: 162px;
    right: 327px;
    z-index: 90;
    max-height: 210px;
    overflow-y: auto;
    background-color: #ffffff;
    border-left: 1px solid $baseLinkBlue;
    border-right: 1px solid $baseLinkBlue;
    border-bottom: 1px solid $baseLinkBlue;
    border-radius: 0px 0px 4px 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

    li {
      padding: 5px 15px;
      cursor: pointer;

      &:hover {
        background-color: #e6f7ff;
      }
    }
  }
}

.submitBtn {
  width: 100px;
  height: 40px;
  float: right;
  margin :0px 60px 60px 0px;
  background-color: $baseRed;
  color: white;
}
.submitBtn:hover {
  width: 100px;
  height: 40px;
  float: right;
  margin :0px 60px 60px 0px;
  background-color: $baseRed;
  color: white;
  border: 0px;
}

.formLabel {
  position: relative;
  color: rgba(0, 0, 0, 0.85);

  &.mustFill {
    position: relative;

    &:before {
      display: inline-block;
      margin-right: 4px;
      color: $baseRequired;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }

  &:after {
    content: ':';
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
  }
}

.thumbWrap {
  display: block;
  margin: 0px 8px 8px 0px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: 104px;
  height: 104px;
  overflow: hidden;

  .thumbInfo {
    position: relative;
    height: 100%;
    overflow: hidden;
    transition: background-color 0.3s;

    img {
      position: static;
      width: 100%;
      height: 100%;
    }
  }

  .prewModal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    transform: all 0.3s;
    color: #fff;
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }
}