@import '../../../assets/scss/base.scss';


.btns {
  text-align: center;
}

.btn {
  background-color: $baseRed;
  border-radius: 2px;

  &:hover {
    background-color: $baseRed;
  }
}

.pName {
  font-size: 14px;
  color: #333333;
  line-height: normal;

  .prodName {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}

.brand {
  display: inline-block;
  margin-bottom: 5px;
  padding: 1px 2px;
  border-radius: 2px;
  border: solid 1px #0169C6;
  font-size: 12px;
  color: #0169C6;
  line-height: normal;
}

.prodProps {
  font-size: 12px;
  color: #999999;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.label {
  position: relative;

  &::after {
    content: ':';
    position: relative;
    top: -.5px;
    margin: 0 6px 0 2px;
  }
}

.price {
  color: #000;
  font-size: 14px;
}