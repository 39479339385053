@import '../../../assets/scss/base.scss';

.searchWrap {
  margin: 0px 20px;
  padding-top: 24px;
}

.btns {
  display: flex;
  align-items: center;
  height: 40px;
}

.btn {
  padding: 0px;
  width: 100px;
  margin-left: 20px;
  border-radius: 2px;

  &:first-child {
    margin-left: 0px;
  }
}

.searchBtn {
  background-color: $baseRed;
  border-color: $baseRed;

  &:focus {
    background-color: $baseRed;
    border-color: $baseRed;
  }
}

.addBtn {
  border-color: $baseLinkBlue;
  color: $baseLinkBlue;
}