@import '../../../assets/scss/base.scss';
.main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.errortitle {
  margin-top: 40px;
  font-size: 18px;
  font-weight: bold;
}

.info {
  width: 700px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.positon {
  position: relative;
  margin: 0px 30px;
}


.icon {
  color: #27D54C;
}

.iconmoen {
  position: absolute;
  left: -45px;
  top: 3px;
  font-weight: bold;
  font-family: SimSun, sans-serif;
  font-size: 30px;
}

.erricon {
  color: #FF4848;
}