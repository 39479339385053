html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}

blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
a {
	text-decoration: none;
}

#root {
  width: 100%;
  height: auto;
  background-color: #F8F8F8;
}

.w1200 {
	margin-left: auto;
	margin-right: auto;
  width: 1200px;
}

.flex {
  display: flex;
}

.bg-white {
  min-height: calc(100vh - 215px);
  background-color: white;
}

.my-bullet {
  margin: 0px 4px;
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.4;
}

.my-bullet-active {
  opacity: 1;
  background-color: #ffffff;
}

.loading-wrapper {
  display: none;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding-top: 100px;
  text-align: center;
  background-color: rgba(230, 247, 255, 0.45);
  z-index: 999;
}

.label {
  position: relative;
  color: rgba(0, 0, 0, 0.85);
}

.label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
