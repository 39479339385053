@import '../../assets/scss/base.scss';

.repayWrapper {
  padding: 50px 20px 20px 20px;
}

.confirmBtn {
  padding: 3px 15px;
  margin-left: 20px;
  background-color: $baseRed;
  color: #ffffff;
  border-radius: 6px;
  border: 1px solid $baseRed;

  &:hover {
    color: #ffffff;
  }
}

.operateRow {
  margin-bottom: 5px;
}

.assetsHint {
  color: $baseRed;
  font-size: 14px;
  line-height: normal;

  .balanceHint {
    margin-right: 15px;
  }
}

.rowTitle {
  margin-bottom: 10px;
  font-size: 16px;
  color: #333333;
  line-height: normal;
}

.bankWrapper {
  display: flex;

  .bankInfo {
    flex: 1;
    margin-right: 24px;
  }

  .explain {
    padding: 24px 24px;
    width: 510px;
    border: solid 1px $baseRed;

    p {
      margin-bottom: 8px;
      font-size: 12px;
      color: #333;
      line-height: normal;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}