@import '../../../../assets/scss/base.scss';

.paneCard {
  position: relative;
  padding-top: 20px;
  background-color: #ffffff;
  height: 460px;
}

.userLand {
  padding: 0px 15px;
  display: flex;
}

.userAvator {
  margin-right: 10px;
  display: block;
  width: 48px;
  height: 48px;
}

.userWelcome {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333333;
}

.land {
  margin-top: 15px;
  padding: 0px 15px;

  .advertPic {
    display: block;
    margin: 0px auto 15px auto;
    width: 100%;
  }

  .btn {
    height: 28px;
    line-height: 28px;
    border: 1px solid #F03A5B;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    color: #F03A5B;

    &.blockbtn {
      display: block;
    }

    &.fillbtn {
      background-color: #F03A5B;
      color: #ffffff;
    }
  }

  &.inlineBtns {
    display: flex;
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal ;

    .btn {
      width: 90px;
      margin-left: 10px;
    }
  }

  &.quickLand {
    display: flex;
  }
}

.quickItem {
  flex: 1;
  cursor: pointer;

  .quickPic {
    display: block;
    margin: 0px auto 8px auto;
    width: 48px;
    height: 48px;
  }

  .txt {
    font-size: 14px;
    color: #666666;
    text-align: center;
    line-height: 1;
  }
}

.company {
  font-size: 14px;
  color: #333333;
  height: 20px;
  line-height: 20px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.quickOrderBtn {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: block;
  height: 44px;
  line-height: 44px;
  font-size: 18px;
  color: #ffffff;
  background-color: #F03A5B;
  text-align: center;

  &:hover {
    color: #ffffff;
  }
}