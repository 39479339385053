@import '../../assets/scss/base.scss';

.paySuccess {
  padding-top: 60px;
}

.successWrap {
  margin: 0px auto 20px auto;
  text-align: center;
}

.payCon {
  margin-bottom: 50px;
  text-align: center;
  font-size: 14px;

  .successText {
    margin-bottom: 10px;
    font-size: 20px;
    color: $baseRed;    
  }

  p {
    color: rgba(153, 153, 153, 0.8);
  }
}

.operateRow {
  text-align: center;

  .btn {
    display: inline-block;
    padding: 5px 20px;
    margin-right: 20px;
    font-size: 14px;
    border: 1px solid $baseRed;
    border-radius: 6px;
    color: rgba(153, 153, 153, 0.8);

    &:last-child {
      margin-right: 0px;
    }

    &.redBg {
      background-color: $baseRed;
      color: #fff;
    }
  }
}
