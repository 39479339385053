@import '../../../assets/scss/base.scss';
.formitemRow {
  :global {
    .ant-form-item {
      display: flex;
    }

    .ant-form-item-control-wrapper {
      flex: auto;
    }
  }
}

.label {
  font-size: 14px;
  color: #333333;

  &:after {
    content: ':';
    position: relative;
    top: -.5px;
    margin: 0 8px 0 2px;
  }
}

.required::before {
  display: inline-block;
  margin-right: 4px;
  color: $baseRequired;
  font-size: 14px;
  font-family: SimSun,sans-serif;
  line-height: 1;
  content: '*';
}

.totalLand {
  margin-bottom: 10px;
}

.rowSelect {
  width: 600px;
}

.rowAction {
  float: right;
  height: 32px;
  line-height: 32px;

  a {
    font-size: 14px;
    color: #333333;

    &:hover {
      color: $baseRed;
    }
  }
}

.btns {
  text-align: center;
}

.confirm {
  background-color: $baseRed;

  &:focus, &:hover {
    background-color: $baseRed;
    border-color: $baseRed;
    color: #FFFFFF;
  }
}