@import '../../../assets/scss/base.scss';

.orderProductWrap {
  margin: 0px 10px;
}

.prodRow {
  border-left: 1px solid #E8E8E8;
  border-right: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8;
}