@import '../../../assets/scss/base.scss';
.prodFilter {
  display: flex;
  margin: 10px 20px;
}

.paginationLand {
  flex: 1;
  text-align: right;

  span {
    font-size: 12px;
    color: #333333;
  }

  a {
    margin-left: 15px;
  }

  .active {
    color: $baseRed;
  }

  .disabled {
    color: rgba(0,0,0, 0.25);
    cursor: not-allowed;
  }
}