@import '../../../assets/scss/base.scss';
.selectorRow {
  margin: 0px 20px;
  background-color: #F1F1F1;
}

.selectorTag {
  position: relative;
  display: flex;
  margin: 0px 16px;

  &.bottomBordered {
    border-bottom: 1px solid rgba(153, 153, 153, 0.2);
  }
  
}

.selectorLabel {
  position: relative;
  height: 34px;
  line-height: 34px;
  font-size: 12px;
  color: #333333;
  letter-spacing: 2px;

  &::after {
    content: ":";
    position: relative;
    top: -.5px;
    margin: 0 4px 0 2px;
  }
}

.selectorContent {
  margin-right: 60px;
  flex: 1;
  height: 34px;
  overflow: hidden;

  &.expanded {
    height: auto;
    overflow: auto;
  }

  li {
    float: left;
    padding: 0px 5px;
    margin-right: 5px;
    line-height: 34px;

    a {
      font-size: 12px;
      color: #333333;

      &:hover {
        color: #1890FF;
      }
    }
  }
}

.selectorBtns {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 34px;
  line-height: 34px;
}

.selectorAction {
  font-size: 12px;
  color:#1890FF;
}