@import '../../../assets/scss/base.scss';
.landTitle {
  margin-bottom: 0px;
  font-size: 18px;
  color: #333333;
  line-height: normal;
}

.landContent {
  margin-top: 15px;
}

.paymentItem {
  float: left;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  width: 130px;
  height: 40px;
  border: 1px solid #BABABA;
  border-radius: 4px;
  font-size: 14px;
  color: #333333;
  cursor: pointer;

  &:first-child {
    margin-left: 0px;
  }

  &:hover {
    border: 2px solid $baseRed;
  }

  &.selected {
    border: 2px solid $baseRed;
  }

  .paymentPic {
    margin-right: 5px;
    display: inline-block;
    width: 25px;
    height: 25px;
  }

  .selectedIcon {
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    right: -1px;
    bottom: -1px;
    background-image: url('~@/assets/images/icon/checked02.png');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
  }
}