@import '../../../assets/scss/base.scss';
.productListItem {
  display: flex;
  padding: 15px 0px;

  li {
    padding: 0px 6px;
    font-size: 12px;
    color: #333333;
  }
}

.titleCol {
  width: 100px;
}

.productCol {
  width: 280px;
}

.productColFture {
  width: 480px;
}


.priceCol {
  width: 280px;
}


.totalCol {
  width: 120px;
  text-align: center;
}

.TimeCol {
  width: 90px;
  text-align: center;
}

.priceCell {
  width: 250px;
}

.quantityCell {
  width: 130px;
}

.imgBox {
  float: left;
  display: flex;
  align-items: center;
  width: 80px;
  height: 80px;
  border: 1px solid #eeeeee;
  cursor: pointer;
  overflow: hidden;

  .pic {
    display: block;
    width: 80px;
  }
}

.productMedia {
  margin-left: 90px;
}




.brand {
  display: inline-block;
  margin-bottom: 5px;
  padding: 1px 2px;
  border-radius: 2px;
  border: solid 1px #0169C6;
  font-size: 12px;
  color: #0169C6;
  line-height: normal;
}

.name {
  margin-bottom: 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: normal;
}

.prodProps {
  font-size: 12px;
  color: #999999;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .propsLabel {
    position: relative;

    &::after {
      content: ':';
      position: relative;
      top: -.5px;
      margin: 0 6px 0 2px;
    }
  }
}

.priceRow {
  display: flex;
  margin-top: 5px;

  &:first-child {
    margin-top: 0px;
  }

  .priceCell {
    flex: 1;
  }

  .quantityCell {
    width: 120px;
  }
}

.inlineCol {
  display: inline-block;
  vertical-align: middle;
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .alignCenter {
    display: flex;
    align-items: center;
    height: 24px;
  }
}

.redColor {
  color: $baseRed;
}
