@import '../../../assets/scss/base.scss';
.shopOrderHeader {
  display: flex;
  background-color: #F1F1F1;
  border-left: 1px solid #E8E8E8;
  border-right: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8;

  li {
    padding: 0px 6px;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    color: #434343;
  }
}

.productCol {
  width: 280px;
}

.titleCol {
  width: 100px;
  color: #333333;
  font-weight: 700;
  text-align: center;
}

.priceCol {
  display: flex;
  width: 272px;
}

.totalColFure {
  width: 120px;
  text-align: center;
}

.totalCol {
  width: 120px;
  text-align: center;
}

.priceCell {
  width: 130px;
}

.quantityCell {
  width: 120px;
}