@import '../../../assets/scss/base.scss';
.modalContainer {
  margin: 0px auto;
  width: 500px;

  :global {
    .ant-form-item {
      display: flex;
    }

    .ant-form-item-label {
      width: 90px;
    }

    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
}

.textareaRow {
  display: flex;

  .hidden {
    width: 0px;
  }
}

.formitemText {
  flex: 1;
  position: relative;

  .textArea {
    padding-bottom: 34px;
  }

  .textareaBtns {
    position: absolute;
    bottom: 0px;
    right: 24px;
    left: 24px;
    padding: 0px 0px 12px 0px;
    text-align: right;

    .btn {
      color: #666666;
    }

    .blockBtn {
      border: 0px;
    }

  }
}

.intelOpen:hover {
  color: $baseRed;
  text-decoration: underline;
}

//文本框
.formitemRow {

  :global {
    .ant-input-affix-wrapper {
      .ant-input:not(:first-child) {
        padding-left: 100px;
      }

      .ant-input:not(:last-child) {
        padding-right: 130px;
      }
    }
  }
}

//前缀样式
.formitemLabel {
  font-size: 14px;
  color: #333333;

  //*样式带*号
  .required::before {
    display: inline-block;
    margin-right: 4px;
    color: $baseRequired;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }

  //*样式不带*号
  .wrequired::before {
    display: inline-block;
    margin-right: 4px;
    color: $baseRequired;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: ' ';
  }
}

//联想框样式
.formitemRoww {

  :global {
    .ant-input:not(:first-child) {
      padding-left: 100px;
    }

    .ant-input:not(:last-child) {
      padding-right: 130px;
    }

    .ant-cascader-menus {
      position: absolute;
      z-index: 1050;
      font-size: 14px;
      white-space: nowrap;
      background: #fff;
      border-radius: 4px;
      left: 200px;
      -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }

    .ant-cascader-picker-label {
      position: absolute;
      top: 50%;
      left: 90px;
      width: 100%;
      height: 20px;
      margin-top: -10px;
      padding: 0 20px 0 12px;
      overflow: hidden;
      line-height: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  //label
  .labelp {
    height: 0px;
    position: relative;
    top: 0px;
    z-index: 999;
    left: 10px;
  }
}

//a连接的字体样式
.div_a {
  font-size: 14px;
  color: $baseLinkBlue;

  a:hover {
    color: $baseLinkBlue;
    text-decoration: underline;
  }
}

.btns {
  text-align: center;
}

.btn {
  width: 90px;
  border-radius: 2px;
}

.confirmBtn {
  background-color: $baseRed;
  border-color: $baseRed;

  &:hover, &:focus {
    background-color: $baseRed;
    border-color: $baseRed;
  }
}