@import '../../assets/scss/base.scss';

.commodityContainer {
  padding-bottom: 80px;
}

.pagingZone {
  margin: 40px 20px 0px 20px;
  text-align: center;
}

.placePage {
  height: calc(100vh - 215px);
  text-align: center;
}
