@import '../../../assets/scss/base.scss';
.firstScreen {
  padding-bottom: 48px;
  background-color: #ffffff;
}

.homeBanner {
  .bannerSlide {
    height: 450px;
    overflow: hidden;

    .pic {
      display: block;
      width: 100%;
    }
  }

  .bannerSeat {
    width: 100%;
    height: 450px;
  }
}

.heroSub {
  margin: 0px auto;
  width: 1200px;
  

  .heroTitle {
    height: 60px;
    line-height: 60px;
    font-size: 22px;
    color: #333333;
    font-weight: 200;
  }

  .heroContainer {
    overflow: hidden;
    text-align: center;
  }

  .heroItem {
    display: inline-block;
    width: 228px;
    height: 228px;
    vertical-align: top;
    margin-left: 15px;

    &:first-child {
      margin-left: 0px;
    }

    .imgBox {
      display: block;

      .pic {
        display: block;
        width: 100%;
      }
    }
  }
}

.homeContainer {
  margin: 0px auto; 
  width: 1200px;
}

.titleLine {
  height: 60px;
  line-height: 60px;

  .floorTitle {
    margin-bottom: 0px;
    font-size: 22px;
    font-weight: 200;
    color: #333333;
  }
}

.containerLine {
  padding: 54px 30px 34px 30px;
  background-color: #ffffff;
  text-align: center;
  overflow: hidden;

  .brickList {
    text-align: center;
  }

  .brickItem {
    padding: 20px 0px;
    margin-left: 20px;
    margin-bottom: 20px;
    display: inline-block;
    vertical-align: top;
    width: 210px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);

    a {
      display: block;
    }

    &:first-child {
      margin-left: 0px;
    }

    .imgBox {
      display: flex;
      margin: 0px auto;
      width: 130px;
      height: 130px;
      overflow: hidden;
      align-items: center;
      justify-content: center;

      .pic {
        display: block;
        width: 100%;
      }
    }

    .name {
      margin: 0px 10px 10px 10px;
      overflow: hidden;
      font-size: 14px;
      color: #333333;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .desc {
      margin: 0px 10px 10px 10px;
      font-size: 12px;
      height: 18px;
      color: #b0b0b0;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .price {
      margin: 0px;
      color: $baseRed;

      del {
        margin-left: 8px;
        color: #4E4E4E;
      }
    }
  }

  .recommendBrick {
    text-align: center;

    .brickItem {
      .imgBox {
        margin: 0px auto 10px auto;
      }
    }
  }
}
