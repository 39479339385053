@import '../../assets/scss/base.scss';

.loginWrapper {
  display: block;
  width: 100%;
  height: 618px;
  background: url('~@/assets/images/home/login-bg1.png') no-repeat;
  background-size: cover;
  background-position: 50%;
}

.loginContent {
  position: relative;
  margin: 0px auto;
  width: 1200px;
  height: 100%;
}

.loginForm {
  position: absolute;
  top: 40px;
  right: 20px;
  width: 400px;
  background-color: #ffffff;
}

.loginTabs {
  display: flex;

  a {
    display: block;
    flex: 1;
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-size: 18px;
    color: #666666;
    border-bottom: 1px solid #e8e8e8;

    &:hover {
      color: $baseRed;
    }

    &.active {
      color: $baseRed;
      font-weight: 500;
    }
  }
}

.loginBox {
  padding: 20px;
}

.forgetLand {
  margin-bottom: 20px;
  text-align: right;

  a {
    font-size: 12px;
    color: #666666;

    &:hover {
      color: $baseRed;
      text-decoration: underline;
    }
  }
}

.coagentLand {
  padding-left: 20px;
  padding-right: 20px;
  line-height: 50px;
  height: auto;
  background-color: #fcfcfc;
  border-top: 1px solid #e8e8e8;
  text-align: right;

  .registerLink {
    font-size: 14px;
    color: $baseRed;

    &:hover {
      text-decoration: underline;
    }
  }
}

.loginBtn {
  display: block;
  width: 100%;
  height: 44px;
  font-size: 18px;
  color: #FFFFFF;
  background-color: $baseRed;

  &:hover, &:focus, &:active {
    color: #FFFFFF;
    background-color: $baseRed;
    border-color: $baseRed
  }
}

.landWrap {
  position: relative;
}
