@import '../../assets/scss/base.scss';

.advertCon {
  display: flex;
  height: 30px;
  align-items: center;
}

.labaIcon {
  margin-right: 16px;
  width: 25px;
  height: 21px;
  background: url('../../assets/images/icon/laba.png') no-repeat;
  background-size: 100% 100%;
}

.adSwiper {
  width: calc(100% - 41px);
  height: 30px;
  color: #221e1f;
  font-size: 12px;
}
.advertSlide {
  width: 100%;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}