@import '../../assets/scss/base.scss';

.topAction {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0px 8px;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 12px;
  color: #4d4d4d;
  text-decoration: none;

  &:hover {
    color: #F03A58;
  }
}

.noticedropContainer {
  padding: 8px 15px;
  width: 338px;
  background-color: #ffffff;

  .noticeHeader {
    margin-bottom: 2px;
    text-align: right;
  }

  .noticeContent {
    min-height: 100px;
  }

  .moreBtn {
    font-size: 12px;
    color: #333333;
    line-height: normal;
  }

  .notice {
    padding: 5px 0px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #333333;

    .noticeTag {
      margin-right: 4px;
    }
  }

  .noticeTips {
    height: 120px;
    line-height: 120px;
    text-align: center;
    font-size: 14px;
    color: #999999;

    .noticeBtn {
      color: #6193c6;

      &:hover {
        color: #F03A5B;
      }
    }
  }
}