@import '../../../assets/scss/base.scss';

.homeBrickrow {
  display: flex;
  margin: 0px auto 15px auto;
  width: 1200px;

  .menuPane {
    width: 200px;
    height: 460px;
  }

  .bannerPane {
    margin: 0px 10px;
    width: 740px;
    height: 460px;
  }

  .paneRight {
    width: 240px;
    height: 460px;
    background-color: #ffffff;
  }
}

.row {
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0px;
  }
}
