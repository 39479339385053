@import '../../../assets/scss/base.scss';

.clear {
  display: block;
  overflow: hidden;
  clear: both;
  height: 0;
  line-height: 0;
  font-size: 0;
}

.label::after {
  content: ':';
  position: relative;
  top: -.5px;
  margin: 0 8px 0 2px;
}

.orderTableWrap {
  margin: 0px 10px;
}

.listItem {
  margin-bottom: 15px;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

.orderTh {
  padding: 0px 10px;
  height: 30px;
  line-height: 30px;
  background-color: #f8f8f8;
  font-size: 12px;
  color: #333333;
}

.orderBd {
  display: flex;
  font-size: 12px;
  color: #333333;

  .bdCol:last-child {
    border-right: none;
  }
}

.bdCol {
  border-right: 1px solid #DDDDDD;
}

.orderCol {
  flex: 1;

  .goodsList:last-child {
    border-bottom: none;
  }
}

.amountCol {
  padding: 10px;
  width: 160px;
}

.consigneeCol, .statusCol, .actionCol {
  padding: 10px;
  width: 120px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dealTime {
  margin-right: 30px;
}

.orderNo {
  a {
    color: #333333;
    font-size: 12px;

    &:hover {
      color: $baseRed;
    }
  }
}

.goodsList {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #dddddd;
}

.goodsName {
  width: 250px;
  height: 32px;
  line-height: 16px;
  overflow: hidden;

  a {
    color: #333333;

    &:hover {
      color: $baseRed;
    }
  }
}

.goodsQuantity {
  margin-left: 20px;
  width: 80px;
  color: #666666;
}

.goodsRepair {
  margin-left: 10px;
  flex: 1;
  color: #666666;

  a {
    color: #666666;

    &:hover {
      color: $baseRed;
    }
  }
}

.btn {
  display: block;
  margin-bottom: 6px;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
  background-color: $baseRed;
  width: 80px;
  border-radius: 2px;

  &:hover, &:focus {
    background-color: $baseRed;
    border-color: $baseRed;
  }
}

.actionCol {
  .btn:last-child {
    margin-bottom: 0px;
  }
}

.linkBtn {
  color: $baseLinkBlue;

  &:hover {
    text-decoration: underline;
  }
}

.orderStatus {
  margin-bottom: 5px;
  color: #000000;
  line-height: normal;
}

