@import '../../../assets/scss/base.scss';
.formitemRow {
  :global {
    .ant-form-item {
      display: flex;
    }

    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
}

.uploadListItem {
  position: relative;
}

.uploadIcon {
  position: absolute;
  top: 0px;
  color: rgba(0,0,0,.45);
  font-size: 14px;
}

.uploadName {
  display: inline-block;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.uploadAction {
  position: absolute;
  right: 0px;
  color: #1890FF;
}