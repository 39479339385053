@import '../../assets/scss/base.scss';
.formitemRow {
  height: 85px;

  :global {
    .ant-input-affix-wrapper {
      .ant-input:not(:first-child) {
        padding-left: 100px;
      }

      .ant-input:not(:last-child) {
        padding-right: 130px;
      }
    }

    .has-error .ant-form-explain {
      position: absolute;
      left: 410px;
      top: 0px;
      display: flex;
      align-items: center;
      width: 220px;
      height: 50px;
    }
  }

  .formInput {
    height: 50px;
  }

  .formitemLabel {
    font-size: 14px;
    color: #333333;

    .required::before {
      display: inline-block;
      margin-right: 4px;
      color: $baseRequired;
      font-size: 14px;
      font-family: SimSun,sans-serif;
      line-height: 1;
      content: '*';
    }
  }
}

.findPwd {
  border-top: 1px solid #BABABA;
  background-color: #ffffff;
}

.findpwdContent {
  margin: 0px auto;
  padding-bottom: 35px;
  width: 400px;

  .title {
    margin: 50px 0px 20px 0px;
    font-size: 16px;
    color: #999999;
  }

  .btn {
    width: 400px;
    height: 50px;
  }
}

.confirmBtn {
  background-color: $baseRed;

  &:hover {
    background-color: $baseRed;
  }
}

.resultLand {
  margin: 0px auto;
  padding: 24px 0px 35px 0px;
  width: 400px;

  .resultIcon {
    margin-bottom: 24px;
    text-align: center;
  }

  .resultTitle {
    font-size: 24px;
    color: #333333;
    text-align: center;
  }

  .resultSubtitle {
    margin-bottom: 24px;
    font-size: 14px;
    color: #666666;
    text-align: center;
  }

  .resultBtns {
    text-align: center;

    .btn {
      margin-right: 20px;
      width: 100px;
      height: 40px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}
