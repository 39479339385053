@import '../../assets/scss/base.scss';

.payPage {
  padding-bottom: 32px;

  .pageTitle {
    margin-bottom: 16px;
    padding-top: 24px;
    border-bottom: 2px solid #999999;
    line-height: normal;

    h4 {
      padding-bottom: 6px;
      margin-bottom: 0px;
      font-size: 18px;
      color: #333333;
    }
  }

  .payBtn {
    padding: 0px;
    font-size: 16px;
    color: #ffffff;
    font-weight: 500;
    letter-spacing: 3px;
    height: 40px;
    width: 100px;
    background-color: $baseRed;
    border-radius: 2px;
  }
}

.messPop {
  p {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  .messText {
    padding: 0px 5px;
    color:  $baseRed;
  }
}

.payPop {
  margin: 0px auto;
  width: 300px;

  .payTit {
    margin-bottom: 10px;
    font-size: 16px;
    color: #333333;
    text-align: center;
  }
}

.row {
  margin-top: 24px;
}