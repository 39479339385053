@import '../../../assets/scss/base.scss';
.errorRow {
  display: flex;
}

.rowContent {
  flex: 1;
  padding: 5px;
  text-align: center;
}

.hintText {
  margin-bottom: 4px;
  font-size: 12px;
  color: #333333;

  em {
    color: #F03A58;
  }
}

.rowAction {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  width: 100px;
}

.errorDetail {
  color: #F03A58;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.correctBtn {
  width: 46px;
  font-size: 12px;
  background-color: #F03A58;
  border-color: #F03A58;
  border-radius: 2px;

  &:focus {
    background-color: #F03A58;
    border-color: #F03A58;
  }
}

.titleLand {
  display: flex;
  width: 500px;

  .title {
    flex: 1;
    font-size: 14px;
    color: #333333;
  }

  .closeWrap {
    display: block;
    width: 30px;
    text-align: right;
    color: #1890FF;
  }
}

.searchLand {
  :global {
    .ant-input {
      border-left: 1px solid #F03A58;
      border-right: 1px solid #F03A58;
      border-top: 1px solid #F03A58;
      border-radius: 0px;
    }
  }
}

.resultLand {
  min-height: 220px;
  border: 1px solid #F03A58;
}

.resultRow {
  display: flex;
  align-items: center;
  padding: 5px;
  text-align: left;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    background-color: rgba(51, 51, 51, 0.05);
  }
}

.resultContainer {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  color: #333333;

  .brandTag {
    display: inline-block;
    padding: 1px 2px;
    margin-right: 5px;
    border-radius: 2px;
    border: solid 1px #0169C6;
    font-size: 12px;
    color: #0169C6;
    line-height: normal;
  }
}

.resultBtns {
  margin-left: 10px;
  width: 50px;
  text-align: right;

  .popBtn {
    font-size: 12px;
    height: 22px;
    background-color: #F03A58;
    border-radius: 2px;
  }
}