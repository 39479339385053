@import '../../../assets/scss/base.scss';
.list {
  border: 1px solid $baseRed;
}

.listItem {
  display: flex;
  height: 45px;
  line-height: 45px;
  font-size: 14px;
  color: #333333;
  text-align: center;
}

.listItemMeta {
  width: 130px;
  color: #FFFFFF;
  background-color: $baseRed;
}

.listItemContent {
  flex: 1;
}

.listItemAction {
  width: 90px;
  border-left: 1px solid $baseRed;
}

.label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
