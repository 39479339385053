@import '../../assets/scss/base.scss';

.tabsRow {
  margin: 0px 10px;

  :global {
    .ant-tabs-nav {  
      .ant-tabs-tab-active {
        color: $baseRed;
      }

      .ant-tabs-tab:hover {
        color: $baseRed;
      }
    }
            
    .ant-tabs-ink-bar {
      background-color: $baseRed;
    }
  }
}

.pageWrap {
  margin-top: 5px;
  padding-bottom: 20px;
  text-align: center;
}
