@import '../../assets/scss/base.scss';
.cinputNumber {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 0px 30px;
  width: 120px;
  font-size: 14px;
  color: #515a6e;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  overflow: hidden;
  cursor: default;
  user-select: none;

  &.small {
    padding: 0px 24px;
    width: 100px;
  }
}

.inputInner {
  width: 100%;
  height: 30px;
  line-height: 30px;
  outline: 0;
  -moz-appearance: textfield;
  color: #666666;
  border: 0px;
  border-radius: 0px;
  transition: all .2s linear;
  user-select: none;

  :global {
    .ant-input-number-handler-wrap {
      display: none;
    }

    .ant-input-number {
      width: 100%;
      border: 0px;
      border-radius: 0px;
    }

    .ant-input-number:hover, .ant-input-number:focus {
      border-color: transparent;
      box-shadow: none;
      border-right-width: 0px !important;
    }

    .ant-input-number-input {
      text-align: center;
    }
  }
}

.btn {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  position: absolute;
  top: 0;
  text-align: center;
  background-color: #f8f8f9;
  color: #515a6e;
  cursor: pointer;
  user-select: none;
}

.minus {
  left: 0px;
  border-right: 1px solid #dcdee2;
}

.plus {
  right: 0;
  border-left: 1px solid #dcdee2;
}

.small {
  .inputInner {
    height: 22px;
    line-height: 22px;
  }

  .btn {
    width: 22px;
    height: 22px;
    line-height: 24px;
  }
}