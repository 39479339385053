@import '../../../assets/scss/base.scss';
.orderProgress {
  padding-top: 10px;
  margin: 0px 10px;
}

.stateLcol {
  margin: 10px 0px;
  height: 40px;
  border: 1px solid #E8E8E8;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.p_2 {
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.orderNo {
  font-size: 14px;
  color: #333333;
}

.orderStatus {
  font-weight: bold;
  color: #52C41A;
  font-family: Microsoft YaHei;
}

.mediaBtn {

  &:last-child {
    margin-bottom: 0px;
  }

  &:hover {
    border-color: $baseRed;
    color: $baseRed;
  }
}

.mediaBtn_btn {
  height: 24px;
  width: 100px;
  background: $baseRed;
  color: white;

  &:hover {
    border-color: $baseRed;
    background: $baseRed;
    color: white;
  }
}

.stateRcol {
  height: 205px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border: 1px solid #E8E8E8;
}

.progresslabel {
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
}