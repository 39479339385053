@import '../../../../assets/scss/base.scss';

.bannerSlide {
  width: 740px;
  height: 460px;
  overflow: hidden;

  .bannerPic {
    display: block;
    width: 740px;
  }
}

.paginationCustom {
  .swiper-pagination-bullet-active {
    background-color: #ffffff;
  }
}

