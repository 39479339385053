@import '../../../assets/scss/base.scss';
.btns {
  text-align: center;
}

.label {
  font-size: 14px;
  color: #333333;

  &:after {
    content: ':';
    position: relative;
    top: -.5px;
    margin: 0 8px 0 2px;
  }
}

.required::before {
  display: inline-block;
  margin-right: 4px;
  color: $baseRequired;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}