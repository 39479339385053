@import '../../../assets/scss/base.scss';
.orderTotal {
  padding: 20px;
  margin: 0px 10px;
  font-size: 12px;
  color: #333333;
  text-align: right;
  border-left: 1px solid #E8E8E8;
  border-right: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8;
}

.totalCol {
  display: inline-block;
  margin-left: 15px;

  &:first-child {
    margin-left: 0px;
  }

  em {
    font-size: 14px;
  }

  &.redColor {
    color: $baseRed;
  }
}

.label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}