@import '../../../assets/scss/base.scss';
.userMenu {
  margin-top: 10px;
  padding-top: 20px;
  width: 200px;
  background-color: #FFFFFF;
}

.menuItem {
  display: flex;
  align-items: center;
  padding-left: 45px;
  height: 45px;
  font-size: 14px;
  color: #333333;

  &.active,
  &:hover {
    color: $baseRed;
  }

  img {
    display: block;
    margin-right: 10px;
    width: 25px;
    height: 25px;
  }
}

.activeck {
  color: $baseRed;
}