@import '../../assets/scss/base.scss';


.header {
  width: 100%;
}

.searchLand {
  width: 100%;
  background-color: #ffffff;
}

.navitemsLand {
  width: 100%;
  background-color: #ffffff;
}

.searchCon {
  height: 100px;

  .logoWrap {
    float: left;
    margin-top: 20px;
    width: 200px;
    cursor: pointer;
    
    .logoPic {
      display: block;
      height: 60px;
    }
  }

  .searchWrap {
    float: left;
    display: flex;
    align-items: center;
    margin-left: 70px;
    position: relative;
    width: 560px;
    height: 100px;
  }
}

.searchPrefix {
  position: absolute;
  top: 50%;
  left: 10px;
  display: block;
  width: 20px;
  height: 20px;
  background: url('../../assets/images/icon/search_icon_1.png') no-repeat;
  background-size: auto 100%;
  transform: translateY(-50%);
}

.searchInput {
  padding-left: 40px;
  padding-right: 15px;
  width: 480px;
  height: 40px;
  border: none;
  border-color: transparent;
  box-sizing: border-box;
  background-image: none;
  outline: none;
  color: #333333;
  border: 2px solid #0169C6;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  &::-webkit-input-placeholder { /* WebKit browsers */
    color: #999999;
  }

  &::-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #999999;
  }

  &::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #999999;
  }

  &::-ms-input-placeholder { /* Internet Explorer 10+ */
   color: #999999;
  }

}

.searchButton {
  padding: 0px;
  margin: 0px;
  width: 80px;
  height: 40px;
  background-color: #0169C6;
  color: #ffffff;
  font-size: 14px;
  border: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}

.settleup {
  float: right;
  display: flex;
  width: 300px;
  height: 100px;
  align-items: center;
  
  .cw {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-left: 20px;
    width: 140px;
    height: 40px;
    border: 1px solid #CECECE;
    border-radius: 2px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    color: #333333;
    cursor: pointer;

    &:hover {
      color: #333333;
    }

    .cartIcon {
      position: absolute;
      left: 15px;
      top: 50%;
      display: block;
      width: 20px;
      height: 18px;
      background: url('../../assets/images/icon/cart_icon_1.png') no-repeat;
      background-size: auto 100%;
      transform: translateY(-50%);
    }

    &:first-child {
      margin-left: 0px;
    }
  }
}

//搜索结果框
.searchone{
  min-width: 532px;
  position: relative;
}
.searchmain{
  position: absolute;
  width: 560px;
  top: 70px;
}
.searchborder{
  z-index: 99;
  border: 2px solid #0169C6;
  background-color: #fff;
  margin-top: -2px;
  padding: 1px 0;
  position: relative;
}

.li {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.searchli{
  display: block;
  padding: 0px 5px;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  color: #333;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

 span {
    font-size: 14px;
  }
}

.searchli:hover{
  background: rgb(245, 243, 243);
  color: #333;
}