@import '../../assets/scss/base.scss';

.card {
  position: relative;
  padding: 0px 10px 15px 10px;
  background-color: #FFFFFF;
}

.cardTitle {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 15px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #333333;
  font-weight: 700;
  border-bottom: 1px solid #E8E8E8;
}

.userItem {
  display: flex;
  padding: 0px 15px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0px;
  }
}

.itemLabel {
  position: relative;
  font-size: 14px;
  color: #333333;

  &::after {
    content: ':';
    position: relative;
    top: -.5px;
    margin: 0 8px 0 2px;
  }
}

.itemValue {
  margin-right: 20px;
  flex: 1;
  font-size: 14px;
  color: #333333;
}

.itemBtn {
  font-size: 14px;
  color: $baseLinkBlue;
  font-family: 'MicrosoftYaHei';

  &:hover {
    text-decoration: underline;
  }
}

.listView {
  padding: 0px 15px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0px;
  }
}

.listContent {
  float: left;
  width: 260px;

  h4 {
    margin-bottom: 8px;
    font-size: 16px;
    color: #333333;
  }

  p {
    font-size: 12px;
    color: #999999;
  }
}

.listControl {
  float: right;
  width: 100px;
  text-align: right;

  .btn {
    font-size: 14px;
    color: $baseLinkBlue;
    font-family: 'MicrosoftYaHei';

    &:hover {
      text-decoration: underline;
    }

    &.blockBtn {
      display: block;
    }
  }
}

.userName {
  max-width: 180px;
  padding-top: 40px;
  font-size: 24px;
  color: #000000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.payCon {
  .noCopy {
    user-select: none;
  }
}

.flowCon {
  padding-right: 10px;
  height: 400px;
  overflow-y: auto;
}

.balanceFlow {
  margin-bottom: 15px;
  width: 100%;
  font-size: 14px;
  color: #333333;

  th {
    padding: 10px 16px;
    overflow-wrap: break-word;
    background: #f8f6f6;
  }

  td {
    padding: 10px 16px;
    border-bottom: 1px solid #e8e8e8;
    overflow-wrap: break-word;
  }

  .c1 {
    width: 200px;
  }

  .c2 {
    width: 200px;
  }
}

.pageinaRow {
  text-align: right;
}

.realCon {
  display: inline-block;
  margin-right: 16px;
  width: calc(100% - 126px);
  height: 44px;
  border: solid 1px #dadada;
  border-radius: 4px;

  .realImg {
    display: inline-block;
    vertical-align: top;
    height: 44px;
    overflow: hidden;
    width: calc(100% - 50px);

    .codeImg {
      display: block;
      height: 42px;
      border-radius: 4px;
    }
  }
}

.refreshBtn {
  display: inline-block;
  vertical-align: top;
  width: 50px;
  height: 42px;
  line-height: 44px;
  text-align: center;
  border-left: 1px solid #dadada;
}

.formExtra {
  position: relative;

  &:before {
    content: '*';
    display: inline-block;
    margin-right: 4px;
    color: $baseRequired;
    font-size: 14px;
    font-family: 'SimSun', 'sans-serif';
    line-height: 1;
  }
}

.formWrap {
  :global {
    .ant-form-item {
      display: flex;
    }

    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
}

.formitemRow {

  :global {
    .ant-input-affix-wrapper {
      .ant-input:not(:first-child) {
        padding-left: 100px;
      }

      .ant-input:not(:last-child) {
        padding-right: 130px;
      }
    }
  }
}

.name_div_btnok{
  background: $baseRed;
  width: 120px;
  color: white;
}

.name_div_btncenal{
  width: 120px;
}

.myInfotitle {
  position: relative;
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  text-overflow: ellipsis;
  font-weight: bold;
  background: white;

  &::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 10px;
    right: 10px;
    height: 1px;
    background-color: #E8E8E8;
  }
}