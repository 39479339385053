@import '../../../assets/scss/base.scss';


.batchText {
  display: flex;
  position: relative;
  margin-bottom: 15px;
}

.batchInputs {
  flex: 1;
}

.batchBtns {
  margin-left: 24px;
  width: 200px;
}

.btn {
  padding: 0px;
  display: flex;
  margin-top: 10px;
  width: 200px;
  height: 45px;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color:  #1890ff;
  border-color: #1890ff;
  border-radius: 2px;

  &:first-child {
    margin-top: 0px;
  }
}

.searchBtn {
  color: #ffffff;
  background-color: #F03A58;
  border-color: #F03A58;

  &:focus {
    background-color: #F03A58;
    border-color: #F03A58;
  }
}