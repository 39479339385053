@import '../../assets/scss/base.scss';
.shelvesDetail {
  margin: 0px auto;
  padding: 20px;
  width: 1200px;
  background-color: #ffffff;
}

.editBtn {
  border: 1px solid #1890FF;
  color: #1890FF;
  border-radius: 2px;
}
