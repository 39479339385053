@import '../../../assets/scss/base.scss';
.main {
  border: 1px solid #E8E8E8;
  width: 970px;
  margin: 10px 0px;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap
}

.main_one,
.main_three,
.main_two {
  flex: 1;
}

.main_two {
  border-left: 1px solid #E8E8E8;
  border-right: 1px solid #E8E8E8;
}

.contexts {
  margin: 20px 20px;
}

.contexts p:nth-child(1) {
  font-weight: bold;
  font-size: 14px;
}

.contexts p {
  margin-top: 10px;
  word-break: break-all;
}