@import '../../../assets/scss/base.scss';
.prodDesc {
  font-size: 18px;
  color: #000000;
  line-height: 40px;
}

.picItem {
  text-align: center;

  .detailimg {
    display: block;
    width: 100%;
  }
}

.tabBar {
  position: relative;
  border-bottom: 1px solid #e8e8e8;
  outline: none;

  .tabItem {
    position: relative;
    display: inline-block;
    height: 100%;
    margin: 0 32px 0 0;
    padding: 4px 16px;
    font-size: 14px;
    color: #333333;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    &.tabActive {
      color: $baseRed;
      font-weight: 500;
    }
  }
}

.popoverContent {
  position: relative;
  color: #333333;

  .popoverClose {
    font-size: 14px;
    color: $baseRed;
  }
}