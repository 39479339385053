@import '../../../assets/scss/base.scss';
.invoiceLand {
  margin: 0px 20px 32px 20px;
}

.landTitle {
  font-size: 16px;
  color: #333333;
  margin-bottom: 10px;
}

.landContainer {
  display: flex;
  height: 34px;
  line-height: 34px;
  padding: 0px 15px;

  &:hover {
    background-color: #ecf9ff;
  }
}

.invoiceTitle {
  flex: 1;
  margin-right: 15px;
  font-size: 14px;
  color: #000000;
}

.label::after {
  content: ':';
  position: relative;
  top: -.5px;
  margin: 0 8px 0 2px;
}

.editBtn {
  font-size: 14px;
  color: #00a0e9;
}