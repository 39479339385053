@import '../../../assets/scss/base.scss';
.errorRow {
  display: flex;
  margin-left: 15px;
  padding: 15px 0px;
}

.content {
  flex: 1;
  text-align: center;
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  width: 120px;
}

.name {
  color: $baseRed;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.desc {
  margin-bottom: 8px;
  font-size: 12px;
  color: #333333;
}

.btn {
  width: 70px;
  font-size: 12px;
  border-radius: 2px;
}

.correctBtn {
  background-color: $baseRed;

  &:hover, &:focus {
    background-color: $baseRed;
  }
}

.titleLand {
  display: flex;
  width: 520px;

  .title {
    flex: 1;
    font-size: 14px;
    color: #333333;
  }

  .closeWrap {
    display: block;
    width: 30px;
    text-align: right;
    color: $baseLinkBlue;
  }
}

.searchLand {
  :global {
    .ant-input {
      border-left: 1px solid $baseRed;
      border-right: 1px solid $baseRed;
      border-top: 1px solid $baseRed;
      border-bottom: 1px solid $baseRed;
      border-radius: 0px;
    }

    .ant-input:hover, .ant-input:focus {
      border-color: $baseRed;
      box-shadow: none;
    }
  }
}

.resultLand {
  min-height: 220px;
  border-left: 1px solid $baseRed;
  border-right: 1px solid $baseRed;
  border-bottom: 1px solid $baseRed;
}

.resultRow {
  display: flex;
  align-items: center;
  width: 520px;
  padding: 5px;
  text-align: left;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    background-color: rgba(51, 51, 51, 0.05);
  }
}

.resultContainer {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  color: #333333;
}

.brandTag {
  display: inline-block;
  padding: 1px 2px;
  margin-right: 5px;
  border-radius: 2px;
  border: solid 1px #0169C6;
  font-size: 12px;
  color: #0169C6;
  line-height: normal;
}

.tag {
  display: inline-block;
  padding: 1px 6px;
  margin-right: 5px;
  font-size: 12px;
  color: #ffffff;
  border-radius: 0px;
  line-height: normal;

  &.spotTag {
    background-color: #0169C6;
  }

  &.futuresTag {
    background-color: $baseRed;
  }

  &.cloudTag {
    background-color: #FF7744;
  }
}

.resultBtns {
  margin-left: 10px;
  text-align: right;
}