@import '../../../../assets/scss/base.scss';
.brickBox {
  margin: 0px auto;
  width: 1200px;
}

.boxHeader {
  display: flex;
  align-items: center;
  height: 50px;

  .floorIcon {
    display: block;
    margin-right: 10px;
    width: 22px;
    height: 22px;
  }

  .title {
    margin: 0px;
    font-size: 22px;
    color: #333333;
    font-weight: bold;
    font-family: 'Microsoft YaHei';
  }
}

.hotSlide {
  padding-bottom: 20px;
  background-color: #ffffff;
}

.slideContainer {
  display: block;
  
  .pic {
    display: block;
    margin: 20px auto;
    width: 150px;
    height: 150px;
  }

  .priceLand {
    margin: 0px 20px 10px 20px;
    text-align: left;

    .price {
      float: left;
      width: 50%;
      font-size: 14px;
      color: #F03A58;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.oprice {
        color: #999999;
        text-decoration: line-through;
        text-align: right;
      }
    }
  }

  .nameLand {
    display: -webkit-box;
    margin: 0px 20px;
    height: 30px;
    line-height: 15px;
    line-clamp: 2;
    box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    font-size: 12px;
    color: #333333;
    text-align: left;
  }
}