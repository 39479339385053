@import '../../../assets/scss/base.scss';
.titleLand {
  position: relative;
  display: flex;
  padding-bottom: 4px;
  line-height: normal;
  margin-bottom: 15px;
  padding: 12px 20px;

  &.border {
    margin-bottom: 8px;
    border-bottom: 1px solid rgba(216, 213, 213, 0.65);
    margin: 0px 10px;

    .curLine {
      position: absolute;
      left: 0px;
      bottom: -2px;
      width: 96px;
      height: 2px;
      background-color: #F03A58;
    }
  }

  .landTitle {
    flex: 1;
    padding-right: 16px;
    font-size: 14px;
    color: #333;
  }

  .landExtra {

    a {
      font-size: 14px;
      // color: #00a0e9;
    }
  }
}

.noAddress {
  padding: 24px 16px;
}

.addressLand {
  position: relative;

  .openedLand {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.6), #ffffff);
    z-index: 30;

    a:hover {
      color: #1890ff;
      text-decoration: underline;
    }
  }
}

.addressList {
  height: 80px;
  overflow: hidden;

  &.opened {
    padding-bottom: 34px;
    transition: height, 0.3s;
    height: auto;
  }
}

.addressItem {
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 6px;
  height: 34px;
  align-items: center;
  border: solid 1px transparent;

  &:last-child {
    margin-bottom: 0px;
  }

  &:hover {
    background-color: #ecf9ff;
  }

  .addressDetail {
    flex: 1;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
    color: #999999;
  }

  &.selected {

    .addressDetail {
      color: #000000;
    }
  }

  .opBtn {
    margin-right: 16px;
    visibility: hidden;

    &:last-child {
      margin-right: 0px;
    }

    &.red {
      color: $baseRed;
    }
  }

  &.curHover {
    .opBtn {
      visibility: visible;
    }
  }


}

//a连接的字体样式
.div_a {
  font-size: 14px;
  color: $baseLinkBlue;

  a:hover {
    color: #1890ff;
    text-decoration: underline;
  }
}