@import '../../assets/scss/base.scss';
.menuBar {
  background-color: #ffffff;
}

.categoryLand {
  float: left;
  width: 200px;

  .categoryTitle {
    height: 36px;
    line-height: 36px;
    text-align: center;
    background-color: #0169C6;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
  }

  .categorys {
    position: relative;

    .cateWrap {
      display: none;
      position: absolute;
      top: 36px;
      left: 0px;
      width: 200px;
      height: 460px;
      background-color: #ffffff;
      z-index: 20;
    }

    &.dropdown {
      .cateWrap {
        display: block;
      }
    }
  }
}

.navsLand {
  float: left;
  margin-left: 10px;
  margin-right: 10px;
  width: 740px;

  a:hover {
    color: $baseRed;
  }
}

.centerLand {
  float: right;
  padding: 0px 10px;
  width: 240px;
  height: 36px;
  line-height: 36px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: #666666;

  .cespan {
    margin-left: 5px;
    color: $baseRed;
  }
}

.menuWrap {
  margin-bottom: 0px;

  li {
    display: inline-block;
    vertical-align: top;
  }

  .menuItem {
    display: flex;
    padding: 0px 20px;
    height: 36px;
    align-items: center;
    font-size: 16px;
    color: #4c4c4c;

    &:hover{
      color: $baseRed;
    }

    &.curNav {
      color: $baseRed;
    }

    &.cateTitle {
      width: 200px;
      justify-content: center;
      background-color: #00549D;
      color: #ffffff;
    }

    .hotImg {
      margin-left: 5px;
      width: 20px;
    }
  }
}