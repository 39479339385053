@import '../../assets/scss/base.scss';

.payUnderline {
  margin: 0px auto;
  padding: 20px;
  width: 1200px;
  background-color: #ffffff;
}

.row {
  margin-top: 25px;
}

.landTitle {
  margin-bottom: 10px;
  font-size: 18px;
  color: #333333;
  line-height: normal;
}

.stepPic {
  display: block;
  width: 630px;
}

.success {
  padding: 80px 40px 80px 72px;
  background-color: #fafafa;
  border: solid 1px #999999;
}

.successPic {
  display: inline-block;
  vertical-align: top;
  margin-right: 64px;
  width: 105px;
  height: 105px;
  background: url('~@/assets/images/icon/success2.png') no-repeat;
  background-size: 100% 100%;
}

.successBody {
  display: inline-block;
  vertical-align: top;

  .successCon {
    display: inline-block;
    width: 400px;
    padding-top: 16px;
  }

  p {
    font-size: 24px;
    color: #333;
  }

  .toLink {
    padding-top: 16px;

    a {
      color: $baseRed;
      text-decoration: underline;
    }
  }

  .amountInfo {
    display: inline-block;
    padding-top: 32px;
    vertical-align: top;
    width: 400px;
    text-align: right;
    font-size: 18px;
    color: #000000;

    .price {
      font-size: 26px;
      font-weight: 700;
      color: $baseRed;
    }
  }
}

.bankWrapper {
  display: flex;

  .bankInfo {
    flex: 1;
    margin-right: 24px;
  }

  .explain {
    padding: 22px 24px;
    width: 510px;
    border: 1px solid $baseRed;

    p {
      margin-bottom: 8px;
      font-size: 12px;
      color: #000;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

