@import '../../../../assets/scss/base.scss';

.noticeWrap {
  .header {
    font-size: 14px;
    color: #1890FF;
  }

  .headerNav {
    padding: 4px 0px;
    display: inline-block;
    height: 100%;
    text-decoration: none;
    cursor: pointer;
    line-height: normal;
    border-bottom: 1px solid #1890FF;
  }
}

.noticeSwiper {
  height: 90px;
}

.noticeSlide {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    display: block;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    color: #333333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.btns {
  text-align: right;

  .btn {
    font-size: 14px;
    color: #333333;

    &:hover {
      color: $baseRed;
    }
  }
}