@import '../../assets/scss/base.scss';
.elevator {
  position: fixed;
  right: 0px;
  bottom: 25%;
  z-index: 200;
  box-shadow: rgba(50,50,51,.16) 0 2px 8px;
}

.contracts {
  display: block;
  width: 80px;
  height: 54px;
  background-image: url('~@/assets/images/icon/contracts_default.png');
  background-repeat: no-repeat;

  &:hover {
    background-image: url('~@/assets/images/icon/contracts_active.png');
  }
}

.contactItem {
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0px;
  }
}

.cart {
  display: block;
  width: 80px;
  height: 54px;
  background-image: url('~@/assets/images/icon/cart_default.png');
  background-repeat: no-repeat;

  &:hover {
    background-image: url('~@/assets/images/icon/cart_active.png');
  }
}

.order {
  display: block;
  width: 80px;
  height: 54px;
  background-image: url('~@/assets/images/icon/userorder_default.png');
  background-repeat: no-repeat;

  &:hover {
    background-image: url('~@/assets/images/icon/userorder_active.png');
  }
}

.customer {
  display: block;
  width: 80px;
  height: 54px;
  background-image: url('~@/assets/images/icon/customer_default.png');
  background-repeat: no-repeat;

  &:hover {
    background-image: url('~@/assets/images/icon/customer_active.png');
  }
}

.backTop {
  display: block;
  width: 80px;
  height: 44px;
  background-image: url('~@/assets/images/icon/back_top.png');
  background-repeat: no-repeat;
}