@import '../../../assets/scss/base.scss';
.bomTable {
  margin: 0px 20px;
}

.tableHead {
  display: flex;
  background-color:#F1F1F1;

  .headCell {
    margin-left: 15px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #333333;
    text-align: center;

    &:first-child {
      margin-left: 0px;
    }
  }

  .hIndex {
    width: 70px;
  }

  .hName {
    flex: 1;
  }

  .hMoment {
    width: 150px;
  }

  .hAction {
    width: 150px;
  }
}

.listRow {
  display: flex;
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
}

.cell {
  padding: 10px 0px;
  margin-left: 15px;
  font-size: 14px;
  color: #333333;
  text-align: center;

  &:first-child {
    margin-left: 0px;
  }
}

.indexCell {
  width: 70px;
}

.momentCell, .actionCell {
  width: 150px;
}

.nameCell {
  flex: 1;
}

.btn {
  margin-left: 10px;
  font-size: 14px;
  color: $baseLinkBlue;

  &:first-child {
    margin-left: 0px;
  }
}