@import '../../../assets/scss/base.scss';
.formitemRow {
  height: 85px;

  :global {
    .ant-input-affix-wrapper {
      .ant-input:not(:first-child) {
        padding-left: 100px;
      }

      .ant-input:not(:last-child) {
        padding-right: 130px;
      }
    }

    .has-error .ant-form-explain {
      position: absolute;
      left: 410px;
      top: 0px;
      display: flex;
      align-items: center;
      width: 220px;
      height: 50px;
    }
  }

  .formInput {
    height: 50px;
  }

  .formitemLabel {
    font-size: 14px;
    color: #333333;

    .required::before {
      display: inline-block;
      margin-right: 4px;
      color: $baseRequired;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }
}

.agreementLink {
  color: #999999;

  &:hover {
    color: $baseRed;
  }
}

.btn {
  padding: 0px;
  width: 400px;
  height: 50px;
  color: white;
}

.btn:hover {
  padding: 0px;
  width: 400px;
  height: 50px;
  background-color: #fa3058;
  color: white;
  border: 0px;
}

//button背景颜色
.bgcolor {
  background: #F03A58;

  //a连接颜色
  .a {
    color: #F03A58;
  }
}