@import '../../../assets/scss/base.scss';

.modalContainer {
  margin: 0px auto;
  width: 500px;

  :global {
    .ant-form-item {
      display: flex;
    }

    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
}

.searchLand {
  position: relative;

  .uncompanyLand {
    padding-bottom: 15px;
    position: absolute;
    top: 40px;
    left: 80px;
    right: 0px;
    z-index: 90;
    max-height: 210px;
    overflow-y: auto;
    background-color: #ffffff;
    border-left: 1px solid #1890ff;
    border-right: 1px solid #1890ff;
    border-bottom: 1px solid #1890ff;
    border-radius: 0px 0px 4px 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

    li {
      padding: 5px 15px;
      cursor: pointer;

      &:hover {
        background-color: #e6f7ff;
      }
    }
  }
}
