@import '../../../assets/scss/base.scss';
.stepWrap {
  :global {
    .ant-steps-label-vertical .ant-steps-item-content {
      margin-top: 2px;
    }

    .ant-steps-item-title {
      font-size: 14px;
      line-height: normal;
    }

    .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
      background-color: #52c41a;
    }

    .ant-steps-label-vertical .ant-steps-item-tail {
      margin-left: 51px;
      padding: 9.5px 24px;
    }
  }


}

.p {
  padding-bottom: 20px;
  font-family: '微软雅黑';
}

.wh {
  width: 35px;
  height: 35px;
}