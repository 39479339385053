@import '../../../../assets/scss/base.scss';
.filters {
  display: flex;
  margin-bottom: 32px;
}

.filterItem {
  flex: 1;
  margin-left: 20px;

  &:first-child {
    margin-left: 0px;
  }
}