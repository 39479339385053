@import '../../../../assets/scss/base.scss';

.brickBox {
  margin: 0px auto;
  width: 1200px;
}

.boxHeader {
  display: flex;
  align-items: center;
  height: 50px;

  .floorIcon {
    display: block;
    margin-right: 10px;
    width: 22px;
    height: 22px;
  }

  .title {
    margin: 0px;
    font-size: 22px;
    color: #333333;
    font-weight: bold;
    font-family: 'Microsoft YaHei';
  }
}

.boxBody {

  .brandSlide {
    cursor: pointer;

    .brandlogoWrap {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 102px;
	    background-color: #ffffff;
      overflow: hidden;

      .pic {
        max-width: 210px;
        border: none;
      }
    }

    .name {
      font-size: 14px;
      color: #000000;
      text-align: center;
    }
  }
}
