@import '../../../assets/scss/base.scss';
.orderLogistics {
  margin: 0px 10px;
  font-size: 12px;
  color: #333333;
  text-align: left;

}

.scrond {
  height: 422px;
  border: 1px solid #E8E8E8;
  align-items: center;
  margin-top: 10px;
  width: 100%;
}