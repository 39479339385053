@import '../../../assets/scss/base.scss';
.totalLand {
  margin: 10px 20px 0px 20px;
  text-align: right;
  font-size: 14px;
  color: #000000;
}

.totalSpan {
  display: inline-block;
  margin-right: 20px;

  &:last-child {
    margin-right: 0px;
  }
}

.spanKey {
  position: relative;

  &::after {
    content: ':';
    position: relative;
    top: -0.5px;
    margin: 0 6px 0 2px;
  }
}

.spanValue {
  font-size: 14px;
  color: $baseRed;
}