@import '../../assets/scss/base.scss';

:global {
  .ant-dropdown-menu-bar {
    display: none;
  }
}

.blue {
  background-color: #00a0e9;
  color: #ffffff;
  border-color: #00a0e9;
}

.topbarWrap {
  width: 100%;
  height: 30px;
	background-color: #f7f7f9;
}

.topcon {
  position: relative;
  margin: 0px auto;
  width: 1200px;
}

.advertLand {
  float: left;
  width: 400px;
}

.topbar {
  display: flex;
}

.topInfo {
  display: flex;
  height: 30px;
  margin-left: auto;
  overflow: hidden;
}

.topAction {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0px 8px;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 12px;
  color: #4d4d4d;
  text-decoration: none;

  &:hover {
    color: $baseRed;
  }
}

.noticedropContainer {
  padding: 8px 15px;
  width: 338px;
  background-color: #ffffff;

  .noticeHeader {
    margin-bottom: 2px;
    text-align: right;
  }

  .moreBtn {
    font-size: 12px;
    color: #333333;
    line-height: normal;
  }

  .notice {
    padding: 5px 0px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #333333;

    .noticeTag {
      margin-right: 4px;
    }
  }

  .noticeTips {
    height: 120px;
    line-height: 120px;
    text-align: center;
    font-size: 14px;
    color: #999999;

    .noticeBtn {
      color: #6193c6;

      &:hover {
        color: $baseRed;
      }
    }
  }
}

.topBtns {
  display: flex;
  margin-left: 36px;
  height: 30px;
  align-items: center;

  .btn {
    text-align: center;
    line-height: 34px;
    font-size: 12px;
    text-decoration: none;
    color: #4d4d4d;

    &.linkLogin {
      margin-right: 12px;
      color: #6193c6;
    }
  }  
}

.dropLand {
  position: relative;
  padding: 0px 16px;
}

.userName {
  display: block;
  width: 120px;
  height: 30px;
  text-decoration: none;
  color: #333333;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.isOpen {
    color: $baseRed;
    background-color:white;
  }
}

.dropList {
  position: absolute;
  top: 30px;
  left: 16px;
  right: 16px;
  background-color: #ffffff;
  z-index: 200;
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.dropItem {
  padding: 6px 0px;
  text-align: center;

  &:hover {
    background-color: #f7f7f9;
    color: #666666;

    a {
      color: #666666;
    }
  }

  a {
    display: block;
    color: #666;
  }
}