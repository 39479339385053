@import '../../../assets/scss/base.scss';
.formitemRow {
  :global {
    .ant-form-item {
      display: flex;
    }

    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
}

.pictureWrap {
  display: inline-block;
  margin-left: 10px;
  vertical-align: top;
  zoom: 1;

  &:first-child {
    margin-left: 10px;
  }
}

.required::before {
  display: inline-block;
  margin-right: 4px;
  color: $baseRequired;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.hideRequired::before {
  display: inline-block;
  margin-right: 4px;
  color: transparent;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}