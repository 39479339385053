@import '../../assets/scss/base.scss';

.introContent {
  width: 100%;
  height: auto;
  margin-top: 70px;

  p {
    white-space: pre-wrap;
    min-height: 1em;
  }
}

.operateLand {
  margin-top: 30px;
  text-align: center;
}

.backBtn {
  display: inline-block;
  padding: 8px 16px;
  color: #ffffff;
  font-size: 14px;
  border-radius: 4px;
  background-color: #00549D;

  &:hover {
    color: #ffffff;
  }
}
